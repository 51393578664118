import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function PulldownItem(props) {
    return (
        <Autocomplete
            id={"combo-box_" + props.item.id}
            autoHighlight
            autoSelect
            value={props.item.value===""?null:props.item.value}
            inputValue={props.item.value}
            options={props.item.choices}
            getOptionLabel={(option) => ""+option}
            getOptionSelected={(option, value) => option.toLowerCase().indexOf(value.toLowerCase()) !== -1}
            onInputChange={(evt,newValue) => {
                props.onChange(props.item,newValue);
            }}
            fullWidth
            renderInput={(params) => <TextField {...params} label={props.item.text} variant="standard" error= {props.item.errorText !== undefined ? true:null}
            helperText={props.item.errorText !== null? props.item.errorText:""} 
            //required={(props.item.required !== true || props.item.text.trim().endsWith(":")) ? null:true}
            />}
        />

    )
}