import React, { Component } from 'react';
import '../../styles/App.css';
import HeaderFormalities from '../header_footer/index_headerFormailites';


class PrivacyStatement extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        return (
            <div>
                <HeaderFormalities screenName="Impressum" previousScreen="login" backButton="true" />
                <div className="Impressum">
                    <p>
                        Herausgeber:<br/>
                        Fachhochschule Vorarlberg GmbH<br/>
                        Hochschulstraße 1<br/>
                        6850 Dornbirn<br/>
                        Österreich<br />
                        +43 5572 792<br/> 
                        info@fhv.at<br/>
                        <a href="https://www.fhv.at/forschung/nutzerzentrierte-technologien/laufende-projekte/wohlbefinden-von-kindern/" alt="Link zum Forschungszentrum Nutzerzentrierte Technologien">www.fhv.at/uct</a>
                     </p>
                    <p>
                        in Kooperation mit:<br/>
                        Amt der Vorarlberger Landesregierung<br/>
                        Abteilung Elementarpädagogik, Schule und Gesellschaft<br/>
                        Fachbereich Jugend und Familie<br/>
                        Landhaus, Römerstraße 15, 6901 Bregenz<br/>
                        <a href="https://www.vorarlberg.at/familie" alt="Link zu Websites des Fachbereichs Jungend und Familie">www.vorarlberg.at/familie</a>
                    </p>
                    <p>
                        Gesamtleitung: <br/>Guido Kempter (FH Vorarlberg)
                    </p>
                    <p>
                        Messverfahren: <br/>Ulrike Lichtinger, Marlis Schedler, Ursula Rigger (PH Vorarlberg)
                    </p>
                    <p>
                        Abstimmung: <br/>Bertram Strolz (Akademie für Positive Psychologie)
                    </p>
                    <p>
                        Realisierung: <br/>Walter Ritter, Lukas Stadelmann (FH Vorarlberg)
                    </p>
                    <p>
                        Gestaltung: <br/>Sarah Hohpe, Matthias Nachbaur (FH Vorarlberg)
                    </p>
                    <p>
                        Steuerungspartnerschaft: <br/>Heike Mennel-Kopf, Eveline Kloos (Land Vorarlberg)
                    </p>                
                </div>
            </div >
        );
    }
}

export default (PrivacyStatement);
