import React, { Component } from 'react';
import '../../styles/App.css';
import HeaderSurvey from '../header_footer/index_headerSurvey';
import Typography from '@material-ui/core/Typography';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { connect } from 'react-redux';

const styles = theme => ({
  grow: {
    flexGrow: 1,
  },
  button: {
    "&:hover": {
      backgroundColor: "#90B134"
    }
  },
  buttonBreak: {
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#90B134',
      contrastText: "white" //button text white instead of black 
    },
    secondary: {
      main: '#FFFFFF',
      contrastText: '#90B134',
    }
  },
  typography: {
    button: {
      textTransform: "none",
      fontSize: 16,
    },
    subtitle1: {
      textAlign: "left"
    }
  }
});

class SurveyDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openValidationTextDialog: false,
    };
    this.handleGoToNextPage = this.handleGoToNextPage.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.handleCancelSurvey = this.handleCancelSurvey.bind(this);
    this.goBackToSurvey = this.goBackToSurvey.bind(this);
    this.handleBreakSurvey = this.handleBreakSurvey.bind(this);
  }

  componentDidMount() {
    if (this.props.state === undefined || this.props.state.survey === undefined || this.props.state.survey.id === undefined ) { //only if we have a valid survey
      this.props.history.push('/umfrage'); 
    } 
  }

  handleGoToNextPage() {
    if (this.props.state.survey.sections[0].questions.length > 0) {
      this.props.history.push('/survey/2');
    } else {
      this.props.history.push('/survey/3');
    }
  }

  formatDate() {
    var date = new Date(this.props.state.survey.dateCreated);
    //alert(date.toISOString()) --> Falls das ISO Format verwendet werden muss um die Invitation zu generieren
    var year = date.getFullYear();
    var month = date.getMonth() + 1 //getMonth is zero based;
    var day = date.getDate();
    var formatted = year + "-" + month + "-" + day;

    return formatted;
  }


  handleCancelSurvey() {
    if (sessionStorage.getItem("participationUser") === "true") {
      this.props.history.push('/dashboard');
    } else {
      this.props.history.push('/umfrage');
    }
  }

  goBackToSurvey() {
    this.setState({ openValidationTextDialog: false });

  }

  handleBreakSurvey() {
    this.setState({ openValidationTextDialog: true });
  }

  render() {
    const { classes } = this.props;
    const { fullScreen } = this.props;
    const descriptionLines = this.props.state !== undefined && this.props.state.survey !== undefined && this.props.state.survey.description !== undefined ? this.props.state.survey.description.split(/\r\n|\r|\n/g):[];
    //console.log(descriptionLines);
    //console.log(this.props.state.survey);
    return (
      <div className="App">
        <HeaderSurvey screenName={this.props.state.survey.title} />
        <div className="contentSurveyDescription">
          <div className="surveyInformation">
            <Typography variant="subtitle1" className={classes.grow} color="textPrimary">
            {descriptionLines.map((line,index) =>
              <div key={index}>{line}</div>
            )}
              
            </Typography>
            <br />
            <MuiThemeProvider theme={theme}>
              <Button className={classes.buttonBreak} color="primary" onClick={this.handleBreakSurvey}>Abbrechen</Button>
              <Fab className={classes.button} variant="extended" color="primary" onClick={this.handleGoToNextPage}>
                Weiter
              </Fab>
            </MuiThemeProvider>
          </div>
          <Dialog
            fullScreen={fullScreen}
            open={this.state.openValidationTextDialog}
            onClose={this.handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">{"Umfrage vorzeitig beenden?"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Willst du diese Bewertung wirklich vorzeitig beenden?
                Deine jetzigen Eintragungen gehen dadurch verloren.
                    </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MuiThemeProvider theme={theme}>
                <Button className={classes.buttonBreak} onClick={this.handleCancelSurvey} color="primary">
                  Bewertung Abbrechen
                            </Button>
                <Fab className={classes.button} variant="extended" color="primary" onClick={this.goBackToSurvey}>
                  Zurück zur Bewertung
                            </Fab>
              </MuiThemeProvider>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    state: state
  };

}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default withMobileDialog()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SurveyDescription)));