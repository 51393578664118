import React, { Component } from 'react';
import '../../styles/App.css';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
});

class HeaderApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className="App">
          <div className={classes.root}>
            <AppBar position="static" style={{ backgroundColor: '#90B134' }}>
              <Toolbar>
                <Typography variant="h6" color="inherit" className={classes.grow}>
                  {this.props.screenName}
                </Typography>
              </Toolbar>
            </AppBar>
          </div>
        </div>
      </div >
    );
  }
}

export default withStyles(styles)(HeaderApp);
