import React, { Component } from 'react';
import HeaderApp from '../header_footer/index_headerApp';
import '../../styles/App.css';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#90B134',
            contrastText: "white" //button text white instead of black 
        }
    },
    typography: {
        button: {
            textTransform: "none",
            fontSize: 16,
            fontWeight:400
        }
    }
});

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(6),
    },
    button: {
        marginRight: 1,
        "&:hover": {
            backgroundColor: "#90B134"
        }
    }
});

class IntroOwnSurveys extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    goBackToDashboard = () => {
        this.props.history.push('/dashboard');
    };

    goToOverviewSurveys = () => {
        this.props.history.push('/overviewOwnSurvey');
    };

    render() {
        const { classes } = this.props;
        return (
            <div height="100%">
                <div className="headerReview">
                    <HeaderApp screenName="Umfragen" />
                </div>
                <div className="introOwnSurveys">
                    <div className="textIntroOwnSurveys">
                    <Typography variant="h6" component="h2" onClick={this.openCloseDescriptionSurvey}>
                    In diesem Bereich kannst du andere einladen, ihr Wohlbefinden zu bewerten. Das könnte von Bedeutung sein, wenn du ein Gruppentagebuch führen möchtest.<br/>
                    Dafür benötigst du eine ID.
                        <p/>
                    </Typography>
                    <Typography variant="h6" component="h2" onClick={this.openCloseDescriptionSurvey}>
                    Du kannst hier auch alle bisher durchgeführten Bewertungen mittels Statistik im Detail analysieren. Dafür benötigst du keine ID.
                    </Typography>
                    </div>

                    <MuiThemeProvider theme={theme}>
                        <Button className={classes.buttonBreak} color="primary" onClick={this.goBackToDashboard}>Zurück zu deinem Tagebuch</Button>
                        <Fab className={classes.button} variant="extended" color="primary" onClick={this.goToOverviewSurveys}>
                            Weiter
                            </Fab>
                    </MuiThemeProvider>
                </div>
            </div>
        );
    }
}


export default withStyles(styles)(IntroOwnSurveys);