
import React from 'react';
import NumberTextItem from './NumberTextItem';
import PulldownItem from './PulldownItem';
import TextItem from './TextItem';
import RadioItem from './RadioItem';
import RatingItem from './RatingItem';

export default function QuestionItem(props) {

      let qItem;
      if (props.item.type==='radio') {
        qItem = <RadioItem item={props.item} onChange={props.onChange} />
      } else if (props.item.type==='text') {
        qItem = <TextItem item={props.item} onChange={props.onChange} />
      } else if (props.item.type==='number') {
        qItem = <NumberTextItem item={props.item} onChange={props.onChange} />
      } else if (props.item.type==='rating') {
        qItem = <RatingItem item={props.item} onChange={props.onChange} useSmileys={props.useSmileys} />
      } else if (props.item.type==='pulldown') {
        qItem = <PulldownItem item={props.item} onChange={props.onChange} />
      }
      
    return (
        <div>
            {qItem} 
            {props.item.refCondition==="connected" || props.item.refCondition==="connectedPositive" || props.item.refCondition==="connectedNegative" || props.isReferenceItem === true ?<div/>:<div><br/></div>}
            
        </div>
    )
}