import React, {Suspense, lazy } from 'react';
import '../../styles/App.css';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import StartScreen from '../start_screens/index_login_register';
import Dashboard from '../dashboard/index_dashboard';
import Notfound from '../route_page/notfound';
import Userprofil from '../userprofil/index_userprofil';
import SurveyDescription from '../survey/index_surveyDescription';
import SurveyProfilAttendee from '../survey/index_surveyProfilAttendee';
import SurveyQuestions from '../survey/index_surveyQuestionnaire';
import SurveyHelp from '../survey/index_surveyHelp';
//import StatisticHistogram from '../statistic/index_statisticHistogram';
//import StatisticFrequency from '../statistic/index_statisticFrequency';
//import StatisticDimensions from '../statistic/index_statisticDimensions';
//import SurveyCreation from '../ownSurveys/index_surveyCreation';
import AccessCheckOwnSurvey from '../ownSurveys/index_reviewAccessWithCodeToCreate';
//import OverviewCreatedSurveys from '../ownSurveys/index_overviewCreatedSurveys';
import ParticipationWithoutLogin from '../start_screens/index_participationInSurveyWithoutLogin';
import StatisticKids from '../statistic/index_statisticKids';
import StatisticKidsOverview from '../statistic/index_statisticKidsOverview';
import PrivacyStatement from '../formalities/index_privacyStatement';
import Imprint from '../formalities/index_imprint';
import CalculationMethod from '../formalities/index_calculationMethod';
import DailySurveyStats from '../dashboard/index_dailySurveyStats';
import IntroOwnSurveys from '../ownSurveys/index_introScreenOwnSurveys';
import PlaceholderWithHeader from '../ownSurveys/PlaceholderWithHeader';

const SurveyCreation = lazy(() => import('../ownSurveys/index_surveyCreation'));
const OverviewCreatedSurveys = lazy(()=> import('../ownSurveys/index_overviewCreatedSurveys'));
const StatisticHistogram = lazy(() => import('../statistic/index_statisticHistogram'));
const StatisticFrequency = lazy(() => import('../statistic/index_statisticFrequency'));
const StatisticDimensions = lazy(() => import('../statistic/index_statisticDimensions'));

const routes = () => (
  <Router>
    <Suspense fallback={<PlaceholderWithHeader/>}>
    <Switch>
      <Route exact path="/" component={StartScreen} />
      <Route exact path="/umfrage" component={ParticipationWithoutLogin} />
      <Route exact path="/umfrage/" component={ParticipationWithoutLogin} />
      <Route path="/umfrage/:code" component={ParticipationWithoutLogin} />
      <Route exact path="/jetzt" component={ParticipationWithoutLogin} />
      <Route exact path="/jetzt/" component={ParticipationWithoutLogin} />
      <Route path="/jetzt/:code" component={ParticipationWithoutLogin} />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/userprofil" component={Userprofil} />
      <Route path="/accessCheckOwnSurvey" component={AccessCheckOwnSurvey} />
      <Route path="/ownSurveyCreation" component={SurveyCreation} />
      <Route path="/introOwnSurveys" component={IntroOwnSurveys} />
      <Route path="/overviewOwnSurvey" component={OverviewCreatedSurveys} />
      <Route path="/dailySurveyStats" component={DailySurveyStats} />

      <Route path="/statistic_kids" component={StatisticKids} />
      <Route path="/statistic_kids_overview" component={StatisticKidsOverview} />

      <Route path="/statistic_frequency" component={StatisticFrequency} />
      <Route path="/statistic_histogram" component={StatisticHistogram} />
      <Route path="/statistic_dimensions" component={StatisticDimensions} />

      <Route path="/survey/1" component={SurveyDescription} />
      <Route path="/survey/2" component={SurveyProfilAttendee} />
      <Route path="/survey/3" component={SurveyQuestions} />
      <Route path="/survey/4" component={SurveyHelp} />

      <Route path="/privacyStatement" component={PrivacyStatement} />
      <Route path="/imprint" component={Imprint} />
      <Route path="/calculationMethod" component={CalculationMethod} />

      <Route component={Notfound} />
    </Switch>
    </Suspense>
  </Router>
);

export default routes;