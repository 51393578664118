import React, { Component } from 'react';
import '../../styles/App.css';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import { Redirect } from 'react-router';


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
});


class HeaderApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: true,
      anchorEl: null,
      logout: false,

      backToKidsStats: false,
      backToLogin: false,
      backToWithoutCode: false,
    };
    this.handleBack = this.handleBack.bind(this);
  }

  handleBack = () => {
    if (sessionStorage.getItem("previousScreen") === "statsKids") {
      this.setState({ backToKidsStats: true });
      return;
    } else if (sessionStorage.getItem("previousScreen") === "login") {
      this.setState({ backToLogin: true });
    } else if (sessionStorage.getItem("previousScreen") === "withoutLogin") {
      this.setState({ backToWithoutCode: true });
    }
  }

  render() {
    const { classes } = this.props;

    if (this.state.backToLogin) {
      return <Redirect push to="/" />;
    } else if (this.state.backToKidsStats) {
      return <Redirect push to="/statistic_kids" />;
    } else if (this.state.backToWithoutCode) {
      return <Redirect push to="/umfrage" />;
    } else {
      return (
        <div>
          <div className="App">
            <div className={classes.root}>
              <AppBar style={{ backgroundColor: '#90B134', position: "fixed" }}>
                <Toolbar>
                  {this.props.backButton ?
                    <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={this.handleBack}>
                      <BackIcon />
                    </IconButton> : null}
                  <Typography variant="h6" color="inherit" className={classes.grow}>
                    {this.props.screenName}
                  </Typography>
                </Toolbar>
              </AppBar>
            </div>
          </div>
        </div >
      );
    }
  }
}


export default withStyles(styles)(HeaderApp);
