import React, { Component } from 'react';
import '../../styles/App.css';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import axios from "axios";
import { connect } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import ResponsiveTextDialog from '../Dialogs/index_responsiveTextDialog';
import {Helmet} from 'react-helmet';
import {apiURL} from '../backend/constants.js';


const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    button: {
        marginRight: 1,
        "&:hover": {
            backgroundColor: "#90B134"
        }
    },
    checkbox: {
        marginRight: 1,
        "&:hover": {
            backgroundColor: "transparent"
        }
    }
});

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#90B134',
            contrastText: "white" //button text white instead of black 
        }
    },
    typography: {
        button: {
            textTransform: "none",
            fontSize: 16,
        }
    }
});

class ParticipationWithoutLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: "",
            checkedA: false,

            dialogTitle: "",
            dialogText: "",
            openValidationDialog: false
        };
        this.handleGoToSurvey = this.handleGoToSurvey.bind(this);
        this.handleCode = this.handleCode.bind(this);
        this.handleOkClick = this.handleOkClick.bind(this);
        this.setTitleAndTextOfDialog = this.setTitleAndTextOfDialog.bind(this);
    }

    componentDidMount() {
        sessionStorage.setItem("previousScreen", "withoutLogin");
        const {code} = (this.props.match.params)
        if (code !== undefined) {
            this.setState({code:code})
            sessionStorage.setItem("invitationCode", code)
            //console.log("setting code " + code);
        } else {
            const invitationCode = sessionStorage.getItem("invitationCode");
            if (invitationCode !== undefined) {
                this.setState({code:invitationCode})
            }
        }
    }

    handleGoToSurvey() {
        //Auf Status überprüfen und nicht auf Fehler warten
        //console.log("going to survey");
        if (this.state.code !== "") {
            var survey;
            var restCallURL = apiURL + "Surveys/questionnaire?code=" + this.state.code;
            axios.get(restCallURL, {}).then((res) => {
                survey = res.data;
                this.props.setDefaultSurveyProfil(survey);
                //console.log(survey);

            }).catch((error) => {
                //Show Error Dialog
                const response = error.response;
                alert(response.data.statusText);
            }).then(() => {
                if (survey.status === "No code entry for code") {
                    this.setTitleAndTextOfDialog("", "Es gibt leider keine Umfrage zu diesem Einladungskode.");
                    this.setState({ openValidationDialog: true });
                    this.setState({ code: "" });
                } else if (survey.status === "Code not valid for today") {
                    this.setTitleAndTextOfDialog("", "Dieser Einladungskode ist entweder bereits abgelaufen oder noch nicht gültig.");
                    this.setState({ openValidationDialog: true });
                    this.setState({ code: "" });
                } else {
                    console.log(survey.status);
                    sessionStorage.setItem("accesscodeSurvey", this.state.code);
                    sessionStorage.setItem("participationUser", "false");
                    this.props.history.push('/survey/1');
                }
            });
        } else {
            this.setTitleAndTextOfDialog("", "Hier mußt du zuerst deinen Einladungskode eingeben.");
            this.setState({ openValidationDialog: true });
        }

    }

    handleCode = event => {
        this.setState({ code: event.target.value });
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    handleOkClick = () => {
        this.setState({ openValidationDialog: false });
    };

    setTitleAndTextOfDialog(title, text) {
        this.setState({ dialogTitle: title });
        this.setState({ dialogText: text });
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Umfrage</title>
                    <link rel="canonical" href="https://uct.labs.fhv.at/tagebuch/umfrage" />
                </Helmet>
                <div className="App">
                    <div className={classes.root}>
                        <AppBar position="static" style={{ backgroundColor: '#90B134' }}>
                            <Toolbar>
                                <Typography variant="h6" color="inherit" className={classes.grow}>
                                    Umfrage zum Wohlbefinden
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </div>
                    <MuiThemeProvider theme={theme}>
                        <div className="contentWithoutLogin">
                            <div className="registerWithoutLogin">
                                <div>
                                    <div className="inlinePrivacyStatement">
                                        <Checkbox
                                            checked={this.state.checkedA}
                                            onChange={this.handleChange('checkedA')}
                                            value="checkedA"
                                            color="primary"
                                            className={classes.checkbox}
                                            inputProps={{
                                                'aria-label': 'primary checkbox',
                                            }}
                                        />
                                        <Typography variant="subtitle2" className={classes.grow} color="textPrimary">
                                        Vor einer Bewertung des eigenen Wohlbefindens bitten wir, die {<Link to='/privacyStatement' className="link">Datenschutzbestimmungen</Link>} zu akzeptieren. 
                                        </Typography>

                                    </div>

                                </div>
                                <p className="lineBreakerStartForm" />
                                {this.state.checkedA ? <div>
                                    <TextField
                                        id="standard-name"
                                        label="Einladungskode"
                                        margin="normal"
                                        value={this.state.code !== undefined ? this.state.code : ""}
                                        onChange={this.handleCode}
                                    />
                                    <br />
                                    <Fab className={classes.button} variant="extended" color="primary" onClick={this.handleGoToSurvey}>
                                        Zur Bewertung
                                </Fab>
                                </div> : <div />}

                            </div>
                        </div>
                    </MuiThemeProvider>
                    {this.state.openValidationDialog ? <ResponsiveTextDialog openDialog="true" closeDialog={this.handleOkClick}
                        title={this.state.dialogTitle} text={this.state.dialogText} /> : null}
                </div>
            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: state
    };

}

function mapDispatchToProps(dispatch) {
    return {
        setDefaultSurveyProfil: (dailySurvey) => dispatch({ type: 'SET_DEFAULT_SURVEY_PROFIL', dailySurvey }),
    };

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ParticipationWithoutLogin));
