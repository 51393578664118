import React, { Component } from 'react';
import '../../styles/App.css';
import HeaderFormalities from '../header_footer/index_headerFormailites';


class PrivacyStatement extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    render() {
        return (
            <div>
                <HeaderFormalities screenName="Berechnungsverfahren" previousScreen="statisticKids" backButton="true" />
                <div className="compute">
                    <p>Es gibt Situationen, über die man oft dankbar ist oder über die man öfter weniger glücklich ist. Hier werden die Situationen angeführt, die du am häufigsten ausgewählt hast.</p>
                    <p>Du kannst bei der täglichen Bewertung folgende Situationen auswählen:<br />
                        _meine Freunde<br/>
                        _mein Zuhause<br/>
                        _meine Schule<br/> 
                        _meine Freizeit <br/>
                        _mein Alleinsein
                    </p>
                </div>
            </div >
        );
    }
}

export default (PrivacyStatement);
