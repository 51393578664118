import React, { Component } from 'react';
import '../../styles/App.css';
import HeaderApp from '../header_footer/index_headerApp';
import FooterApp from '../header_footer/index_footerApp';
import Typography from '@material-ui/core/Typography';
import axios from "axios";
import { connect } from 'react-redux';

import happy_active from '../../icons/happy(1)_active.png';
import friendly_active from '../../icons/friendly(2)_active.png';
import neutral_active from '../../icons/neutral(3)_active.png';
import bad_active from '../../icons/bad(4)_active.png';
import sad_active from '../../icons/sad(5)_active.png';

import {apiURL} from '../backend/constants.js';


class StatisticKidsOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            happyPercentage: 0,
            friendlyPercentage: 0,
            neutralPercentage: 0,
            badPercentage: 0,
            sadPercentage: 0,

            happyCount: 0,
            friendlyCount: 0,
            neutralCount: 0,
            badCount: 0,
            sadCount: 0,

            textToDisplayHappy: false,
            textToDisplayFriendly: false,
            textToDisplayNeutral: false,
            textToDisplayBad: false,
            textToDisplaySad: false,

            accessTokenReload: "",
            userIdReload: -1,
            standardSurveyIdReload: -1,

            didLoadStats: false
        };
        this.computeDimensionPercentages = this.computeDimensionPercentages.bind(this);
        this.computeWidthOfDivs = this.computeWidthOfDivs.bind(this);
    }

    componentDidMount() {
        var statisticOverview;
        if (this.props.state.user.id === -1) {
            var x = document.cookie;
            if (x === "") {
                alert("Ihre Session ist leider nicht mehr aktiv - bitte loggen Sie sich erneut ein.")
                this.props.history.push('/');
            } else {

                var that = this;
                new Promise(function (resolve, reject) {

                    setTimeout(() => resolve(1), 100);

                }).then(function (result) {

                    //Splitting the cookie to get the values
                    var values = x.split("{");
                    values = values[1].split(",");
                    var accessToken = values[0].split(":");
                    var standardSurveyId = values[1].split(":");
                    var userId = values[2].split(":");

                    //store the accesToken and userId in the state 
                    that.setState({ accessTokenReload: accessToken[1].replace(/"/g, "") });
                    that.setState({ standardSurveyIdReload: standardSurveyId[1].replace(/"/g, "") });
                    that.setState({ userIdReload: userId[1].replace(/}/g, "") });
                }).then(function (result) {

                    var restCallURL = apiURL + "Surveys/userAlive";
                    axios.get(restCallURL, { headers: { Authorization: that.state.accessTokenReload } }).then((res) => {
                    }).catch((error) => {
                        //Show Error Dialog 
                        alert("Ihre Session ist leider nicht mehr aktiv - bitte loggen Sie sich erneut ein.");
                        this.props.history.push('/');
                    }).then(() => {
                        that.props.setAccessToken(that.state.accessTokenReload);
                    }).then(function (result) {

                        var restCallURL = apiURL + "Surveys/overviewStats";
                        axios.get(restCallURL, { headers: { Authorization: that.state.accessTokenReload } }).then((res) => {
                            statisticOverview = res.data;
                        }).catch((error) => {
                            //Show Error Dialog
                            alert(error)
                            alert(error.response.statusText);
                        }).then(() => {
                            that.computeDimensionPercentages(statisticOverview.ratingsCount);
                        }).then(() => {
                            that.computeWidthOfDivs();
                        });

                    });
                });
            }
        } else {
            var restCallURL = apiURL + "Surveys/overviewStats";
            axios.get(restCallURL, { headers: { Authorization: this.props.state.user.id } }).then((res) => {
                statisticOverview = res.data;
            }).catch((error) => {
                //Show Error Dialog
                alert(error)
                alert(error.response.statusText);
            }).then(() => {
                this.computeDimensionPercentages(statisticOverview.ratingsCount);
            }).then(() => {
                this.computeWidthOfDivs();
            });
        }
    }

    computeDimensionPercentages(ratingCount) {
        //console.log(ratingCount);
        var sumOfRatings = 0;
        //sumOfRatings = sumOfRatings + ratingCount["1"];
        //sumOfRatings = sumOfRatings + ratingCount["2"];
        //sumOfRatings = sumOfRatings + ratingCount["3"];
        //sumOfRatings = sumOfRatings + ratingCount["4"];
        //sumOfRatings = sumOfRatings + ratingCount["5"];

        let maxRatingCount = 0;
        for (let i=1; i < 6; i++) {
            let rc = parseInt(ratingCount[""+i]);
            sumOfRatings = sumOfRatings + rc
            if (maxRatingCount < rc) {
                maxRatingCount = rc;
            }
        }
        /*
        var onePercent = 100 / sumOfRatings;

        this.setState({ happyPercentage: Math.round((onePercent * ratingCount["1"])) });
        this.setState({ friendlyPercentage: Math.round((onePercent * ratingCount["2"])) });
        this.setState({ neutralPercentage: Math.round((onePercent * ratingCount["3"])) });
        this.setState({ badPercentage: Math.round((onePercent * ratingCount["4"])) });
        this.setState({ sadPercentage: Math.round((onePercent * ratingCount["5"])) });
*/
        this.setState({ happyCount: ratingCount["1"],friendlyCount: ratingCount["2"], neutralCount: ratingCount["3"],badCount: ratingCount["4"], sadCount: ratingCount["5"], maxCount:maxRatingCount });
        /*
        this.setState({ friendlyCount: ratingCount["2"] });
        this.setState({ neutralCount: ratingCount["3"] });
        this.setState({ badCount: ratingCount["4"] });
        this.setState({ sadCount: ratingCount["5"] });
        this.setState({})*/

        if (ratingCount["1"] > 30) {
            this.setState({ textToDisplayHappy: true });
        }
        if (ratingCount["2"] > 30) {
            this.setState({ textToDisplayFriendly: true });
        }
        if (ratingCount["3"] > 30) {
            this.setState({ textToDisplayNeutral: true });
        }
        if (ratingCount["4"] > 30) {
            this.setState({ textToDisplayBad: true });
        }
        if (ratingCount["5"] > 30) {
            this.setState({ textToDisplaySad: true });
        }
    }

    computeWidthOfDivs() {

        let scale = 1.0;
        let cnt = this.state.maxCount;
        let happyCount = this.state.happyCount;
        if ((30+cnt*2+30) > window.innerWidth) {
            scale = window.innerWidth/(30+cnt*3);
            //console.log("scaling: ", scale, window.innerWidth);
        }
        //console.log("maxCount", this.state.maxCount);
        var elemHappy = document.getElementById('happyStats');
        elemHappy.style.width = 30 + (happyCount * 2 * scale) + "px";

        var elemFriendly = document.getElementById('friendlyStats');
        elemFriendly.style.width = 30 + (this.state.friendlyCount * 2 * scale) + "px";

        var elemNeutral = document.getElementById('neutralStats');
        elemNeutral.style.width = 30 + (this.state.neutralCount * 2 * scale) + "px";

        var elemBad = document.getElementById('badStats');
        elemBad.style.width = 30 + (this.state.badCount * 2 * scale) + "px";

        var elemSad = document.getElementById('sadStats');
        elemSad.style.width = 30 + (this.state.sadCount * 2 * scale) + "px";
       
        this.setState({didLoadStats:true});
    }

    render() {
        return (
            <div>
                <HeaderApp screenName="Wohlbefindenstagebuch" />
                <div className="statisticKids">
                    <div className="contentStatisticKids" style={{display:this.state.didLoadStats?"block":"none"}}>
                        <div className="typoDescriptionOverviewStats">
                            <Typography variant="subtitle1" component="h2">
                                So viele Bewertungen hast du in deinem Tagebuch:
                            </Typography>
                        </div>
                        

                        <div className="barhoriz" id="barhoriz">
                            <div className="inlineStatisticKids" >
                                <div id="happyStats" className="hprogressHappy"><p id="textHProgress">{this.state.happyCount} {this.state.textToDisplayHappy ? "Mal" : ""}</p></div>
                                <div><img className="statsRatingImgBar" src={happy_active} alt="happy face"></img>
                                </div>
                            </div>
                            <div className="inlineStatisticKids">
                                <div id="friendlyStats" className="hprogressFriendly"><p id="textHProgress">{this.state.friendlyCount} {this.state.textToDisplayFriendly ? "Mal" : ""}</p></div>
                                <div><img className="statsRatingImgBar" src={friendly_active} alt="friendly face"></img>
                                </div>                                </div>
                            <div className="inlineStatisticKids">
                                <div id="neutralStats" className="hprogressNeutral"><p id="textHProgress">{this.state.neutralCount} {this.state.textToDisplayNeutral ? "Mal" : ""}</p></div>
                                <div><img className="statsRatingImgBar" src={neutral_active} alt="neutral face"></img>
                                </div>
                            </div>
                            <div className="inlineStatisticKids">
                                <div id="badStats" className="hprogressBad"><p id="textHProgress">{this.state.badCount} {this.state.textToDisplayBad ? "Mal" : ""}</p></div>
                                <div><img className="statsRatingImgBar" src={bad_active} alt="unfriendly face"></img>
                                </div>
                            </div>
                            <div className="inlineStatisticKids">
                                <div id="sadStats" className="hprogressSad"><p id="textHProgress">{this.state.sadCount} {this.state.textToDisplaySad ? "Mal" : ""}</p></div>
                                <div><img className="statsRatingImgBar" src={sad_active} alt="sad face"></img>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="footerStatistic">
                    <FooterApp currentScreen="statisticKidsOverview" />
                </footer>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        state: state
    };

}

function mapDispatchToProps(dispatch) {
    return {
        setAccessToken: (accessToken) => dispatch({ type: 'SET_ACCESSTOKEN', accessToken })
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(StatisticKidsOverview);