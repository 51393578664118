import React from 'react'
import HeaderApp from '../header_footer/index_headerApp';

export default function PlaceholderWithHeader(props) {

    return (
        <div height="100%">
            <HeaderApp screenName={props.title !== undefined?props.title:""} />
        </div>

    );
}