import React from 'react';
import TextField from '@material-ui/core/TextField';


export default function TextItem(props) {
    return (
        <TextField
            id={"standard-name_" + props.item.id}
            label={props.item.text}
            margin="normal"
            value={props.item.value}
            placeholder={props.item.placeholder!==undefined?props.item.placeholder:""}
            onChange={(evt) => props.onChange(props.item,evt.target.value)}
            fullWidth
            error =  {props.item.errorText !== undefined ? true:null}
            helperText={props.item.errorText !== null? props.item.errorText:""}
            //required =  {""+props.item.required !== ""+true ? null:true}
            InputLabelProps={{
                shrink: true,
            }}
        />
    )
}