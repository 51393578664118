import React, { PureComponent } from 'react';
import '../../styles/App.css';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import HeaderApp from '../header_footer/index_headerApp';
import { connect } from 'react-redux';
import axios from "axios";
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import FooterApp from '../header_footer/index_footerApp';
import {apiURL} from '../backend/constants.js';

import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/de-at';
import Helmet from 'react-helmet';

import happy from '../../icons/happy(1)_active.png';
import friendly from '../../icons/friendly(2)_active.png';
import neutral from '../../icons/neutral(3)_active.png';
import bad from '../../icons/bad(4)_active.png';
import sad from '../../icons/sad(5)_active.png';

const styles = theme => ({
  button: {
    marginRight: 1,
    "&:hover": {
      backgroundColor: "#90B134"
    }
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#90B134',
      contrastText: "white" //button text white instead of black 
    },
    third: {
      contrastText: "#90B134" //button text white instead of black
    }
  },
  typography: {
    button: {
      fontSize: 16,
    }
  }
});


class Dashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
      statistic: null,
      statisticLoaded: false,
      month: null,

      accessTokenReload: "",
      userIdReload: -1,
      standardSurveyIdReload: -1,

      initialMonth: null,
    };
    this.createDailySurvey = this.createDailySurvey.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.monthChange = this.monthChange.bind(this);
    this.renderDay = this.renderDay.bind(this);
  }

  componentDidMount() {
    let iniMo = new Date();
    //console.log(sessionStorage);
    if (sessionStorage.getItem("initialMonth") !== null) {
      let d = new Date(sessionStorage.getItem("initialMonth"));
      if (d !== undefined) {
        //console.log("session month");
        //console.log(d);
        iniMo = d;
      }
    } //else {
      //console.log("no initial month date");
    //}
    //this.setState({ initialMonth: iniMo });
    var that = this;

    if (this.props.state.user.id === -1) {
      var x = document.cookie;
      if (x === "") {
        alert("Ihre Session ist leider nicht mehr aktiv - bitte loggen Sie sich erneut ein.")
        this.props.history.push('/');
      } else {
          //Splitting the cookie to get the values
          var values = x.split("{");
          values = values[1].split(",");
          var accessToken = values[0].split(":")[1].replace(/"/g, "") ;
          var standardSurveyId = values[1].split(":")[1].replace(/"/g, "") ;
          var userId = values[3].split(":")[1].replace(/"/g, "") ;
          //new Promise(function (resolve, reject) {

          //  setTimeout(() => resolve(1), 100);

        //}).then(function (result) {



          //store the accesToken and userId in the state 
          //that.setState({ accessTokenReload: accessToken[1].replace(/"/g, "") });
          //that.setState({ standardSurveyIdReload: standardSurveyId[1].replace(/"/g, "") });
          //that.setState({ userIdReload: userId[1].replace(/}/g, "") });
        //}).then(function (result) {

          var restCallURL = apiURL + "Surveys/userAlive";
          axios.get(restCallURL, { headers: { Authorization: accessToken } }).then((res) => {
          }).catch((error) => {
            //Show Error Dialog 
            alert("Ihre Session ist leider nicht mehr aktiv - bitte loggen Sie sich erneut ein.");
            this.props.history.push('/');
          //}).then(() => {
          //  that.props.setAccessToken(that.state.accessTokenReload);
          }).then(function (result) {

            //var today = new Date();
            //var month = today.getMonth() + 1;
            let month = iniMo.getMonth() + 1;
            let restCallURLStats = apiURL + "Surveys/overviewStats?year=" + iniMo.getFullYear() + "&month=" + month;
            axios.get(restCallURLStats, { headers: { Authorization: accessToken } }).then((res) => {
              //that.props.setMonthlyStatsOverview(res.data);
              that.setState({ statistic: res.data, statisticLoaded:true, accessTokenReload:accessToken, standardSurveyIdReload:standardSurveyId, userIdReload:userId, initialMonth: iniMo});
            }).catch((error) => {
              //Show Error Dialog
              alert(error.response.statusText);
              that.setState({ statisticLoaded:false, accessTokenReload:accessToken, standardSurveyIdReload:standardSurveyId, userIdReload:userId, initialMonth: iniMo});
            })//.then(function (result) {
            //  that.setState({ statisticLoaded: true });
            //});

            let restCallURL = apiURL + "Surveys/profile";
            axios.get(restCallURL, { headers: { Authorization: accessToken } }).then((res) => {
              that.props.setUserProfile(res.data);
            }).catch((error) => {
              //Show Error Dialog
              alert("Error getting profile " + that.state.accessTokenReload+ " " + error.response.statusText);

            });

          });
        //});
      }
    } else {
      //this.setState({ accessTokenReload: this.props.state.user.id });

      //var today = new Date();
      //var month = today.getMonth() + 1;
      let month = iniMo.getMonth()+1;
      let restCallURLStats = apiURL + "Surveys/overviewStats?year=" + iniMo.getFullYear() + "&month=" + month;
      axios.get(restCallURLStats, { headers: { Authorization: this.props.state.user.id } }).then((res) => {
        //that.props.setMonthlyStatsOverview(res.data);
        this.setState({ statistic: res.data, statisticLoaded:true, accessTokenReload: this.props.state.user.id, initialMonth: iniMo});
        //console.log("update state valid user id month  " + month);
      }).catch((error) => {
        //Show Error Dialog
        alert(error.response.statusText);
        this.setState({ statisticLoaded:false, accessTokenReload: this.props.state.user.id, initialMonth: iniMo});
      })//.then(function (result) {
      //  that.setState({ statisticLoaded: true });
      //});

      let restCallURL = apiURL + "Surveys/profile";
      axios.get(restCallURL, { headers: { Authorization: this.props.state.accessToken } }).then((res) => {
        this.props.setUserProfile(res.data);
      }).catch((error) => {
        //Show Error Dialog
        alert("Error getting profile " + that.state.accessTokenReload+ " " +error.response.statusText);
      });

    }
  }

  createDailySurvey = () => {
    var today = new Date();
    this.props.createDailySurvey(today);
    sessionStorage.removeItem("initialMonth");

    var survey;
    var restCallURL = "";
    if (this.props.state.user.userId !== undefined) {
      restCallURL = apiURL + "Surveys/questionnaire?id=" + this.props.state.userProfile.id;
    } else if (this.props.state.user.userId === undefined) {
      restCallURL = apiURL + "Surveys/questionnaire?id=" + this.state.standardSurveyIdReload;
    }

    axios.get(restCallURL, {}).then((res) => {
      survey = res.data;
      //alert(JSON.stringify(survey));
      this.props.setDefaultSurveyProfil(survey);
    }).catch((error) => {
      //Show Error Dialog
      alert(error.response.statusText);
    }).then(() => {
      //Überprüfen ob Profilfragen ausgefüllt sind oder nicht
      sessionStorage.setItem("participationUser", "true");
      if (survey.sections[0].questions.length > 0) {
        this.props.history.push('/survey/2');
      } else {
        this.props.history.push('/survey/3');
      }
    });
  };

  handleDayClick(day, { selected }) {
    var month = day.getMonth() + 1;
	//console.log("handleDayClick");
    var dayFormatted;
    if (day.getDate() < 10) {
      dayFormatted = "0" + day.getDate().toString();
    } else {
      dayFormatted = day.getDate();
    }
    var monthFormatted;
    if (month < 10) {
      monthFormatted = "0" + month;
    } else {
      monthFormatted = month;
    }

    var dateFormatted = day.getFullYear() + "-" + monthFormatted + "-" + dayFormatted;
    this.props.setFormattedDateStatistic(dateFormatted);

    Object.entries(this.state.statistic.dayStats).forEach(([key, value]) => {
      if (key === dateFormatted) {
        this.props.history.push('/dailySurveyStats');
      }
    });
  }

  monthChange(monthDate) {
    console.log(monthDate);
    var d = new Date(monthDate.getFullYear(), monthDate.getMonth());
    d.setMonth(d.getMonth());

    //this.setState({ initialMonth: d });
    sessionStorage.setItem("initialMonth",d.toISOString());

    //console.log(sessionStorage.getItem("initialMonth"));


    var that = this;
    //new Promise(function (resolve, reject) {

    //  setTimeout(() => resolve(1), 100);

    //}).then(function (result) {

      let year = monthDate.getFullYear();
      let month = monthDate.getMonth() + 1;

      let restCallURLStats = apiURL + "Surveys/overviewStats?year=" + year + "&month=" + month;
      axios.get(restCallURLStats, { headers: { Authorization: that.state.accessTokenReload } }).then((res) => {
        //that.props.setMonthlyStatsOverview(res.data);
        that.setState({ statistic: res.data,statisticLoaded: true, initialMonth:d });
        //console.log("update overview state change month " + month);
      }).catch((error) => {
        //Show Error Dialog
        alert(error.response.statusText);
        that.setState({ statisticLoaded: false, initialMonth: d });
      })//.then(function (result) {
      //  that.setState({ statisticLoaded: true });
      //});
    //});
  }


  /*The renderDay method is called every time this screen is loaded and thus every time the calendar is loaded.
  This method is called once per day. Therefore this method is also used to display the smileys on the calendar.*/
  renderDay(day) {
    var foundSurveyValuation = false;
    var smileyNumb = 0;

    var month = day.getMonth() + 1
    var dayFormatted;
    if (day.getDate() < 10) {
      dayFormatted = "0" + day.getDate().toString();
    } else {
      dayFormatted = day.getDate();
    }
    var monthFormatted;
    if (month < 10) {
      monthFormatted = "0" + month;
    } else {
      monthFormatted = month;
    }
    //console.log("render day " + dayFormatted);console.log(this.state.statistic.dayStats);

    var dateFormatted = day.getFullYear() + "-" + monthFormatted + "-" + dayFormatted;
    Object.entries(this.state.statistic.dayStats).forEach(([key, value]) => {
       //console.log("check : " + key + " dateFormatted: " + dateFormatted);
      if (key === dateFormatted) {
        foundSurveyValuation = true;
        if (value.dailyStats.avg < 1.5) {
          smileyNumb = 1;
        } else if (value.dailyStats.avg >= 1.5 && value.dailyStats.avg < 2.5) {
          smileyNumb = 2;
        } else if (value.dailyStats.avg >= 2.5 && value.dailyStats.avg < 3.5) {
          smileyNumb = 3;
        } else if (value.dailyStats.avg >= 3.5 && value.dailyStats.avg < 4.5) {
          smileyNumb = 4;
        } else if (value.dailyStats.avg >= 4.5) {
          smileyNumb = 5;
        }
        //console.log("assigned smiley: " + smileyNumb)
      }
    });

    if (smileyNumb === 1) {
      return (
        <div>
          <img src={happy} height="25" width="25" alt="happy face"></img>
        </div>
      );
    } else if (smileyNumb === 2) {
      return (
        <div>
          <img src={friendly} height="25" width="25" alt="friendly face"></img>
        </div>
      );
    } else if (smileyNumb === 3) {
      return (
        <div>
          <img src={neutral} height="25" width="25" alt="neutral face"></img>
        </div>
      );
    } else if (smileyNumb === 4) {
      return (
        <div>
          <img src={bad} height="25" width="25" alt="unfriendly face"></img>
        </div>
      );
    } else if (smileyNumb === 5) {
      return (
        <div>
          <img src={sad} height="25" width="25" alt="sad face"></img>
        </div>
      );
    } else if (!foundSurveyValuation) {
      return (
        <div>
          <span>{day.getDate()}</span>
        </div>
      );
    }
  }

  render() {
    const { classes } = this.props;
    const d = new Date();
    const d2 = new Date(d.getFullYear(),d.getMonth());
    const refDate = this.state.initialMonth !== null?new Date(this.state.initialMonth.getFullYear(), this.state.initialMonth.getMonth()):null;
    //console.log("currentDate: ", d2, this.state.initialMonth);

    return (
      <div>
        <HeaderApp screenName="Wohlbefindenstagebuch" thus={this} />
        <div className="contentOverviewPage">
          {this.state.statisticLoaded ?
            <div className="calendar">
              <Helmet>
                <style>{`
                  .DayPicker-TodayButton {
                    color: #90B134;
                    width: 100%;
                  }
                  .DayPicker:focus {
                    outline:none
                  }
                  .DayPicker-wrapper:focus {
                    outline:none
                  }
                  `}</style>
              </Helmet>
              <DayPicker
                selectedDays={this.state.date}
                onDayClick={this.handleDayClick}
                renderDay={this.renderDay}
                showOutsideDays
                initialMonth={this.state.initialMonth}
                month={this.state.initialMonth}
                onMonthChange={this.monthChange}
                localeUtils={MomentLocaleUtils}
                locale={"de-at"}
                todayButton={refDate===null||refDate.getTime()===d2.getTime()? "":"Zeige aktuellen Monat"}
              />
            </div>
            :
            <div></div>}
          <div className="createNewSurvey">
            <MuiThemeProvider theme={theme}>
              <Fab className={classes.button} variant="extended" size="medium" color="primary" onClick={this.createDailySurvey} >
                <AddIcon />
              </Fab>
            </MuiThemeProvider>
          </div>
        </div>

        <footer className="footerStatistic">
          <FooterApp currentScreen="dashboard" />

        </footer>

      </div >
    );
  }
}

function mapStateToProps(state) {
  return {
    state: state
  };

}

function mapDispatchToProps(dispatch) {
  return {
    createDailySurvey: (date) => dispatch({ type: 'CREATE_DAILY_SURVEY', date }),
    setDefaultSurveyProfil: (dailySurvey) => dispatch({ type: 'SET_DEFAULT_SURVEY_PROFIL', dailySurvey }),
    setUserProfile: (userProfile) => dispatch({ type: 'SET_USER_PROFILE_DATA', userProfile }),
    setAccessToken: (accessToken) => dispatch({ type: 'SET_ACCESSTOKEN', accessToken }),
    setFormattedDateStatistic: (dateStatistic) => dispatch({ type: 'SET_FORMATTED_DATE', dateStatistic }),
    setMonthlyStatsOverview: (monthlyStats) => dispatch({ type: 'SET_MONTHLY_STATS_OVERVIEW', monthlyStats })
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dashboard));