import React, { Component } from 'react';
import '../../styles/App.css';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import axios from "axios";
import Fab from '@material-ui/core/Fab';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ResponsiveTextDialog from '../Dialogs/index_responsiveTextDialog';
import {Helmet} from 'react-helmet';
import {apiURL} from '../backend/constants.js';

function PasswordRepeat(props) {
  if (!props.register) {
    return null;
  }

  return (
    <TextField
      id="standard-name_passwordRepeat"
      label="Passwort wiederholen"
      margin="normal"
      value={props.thus.state.passwordRepeat}
      onChange={props.thus.handlePasswordRepeat}
      onKeyPress={(ev) => {
        //console.log(`Pressed keyCode ${ev.key}`);
        if (ev.key === 'Enter') {
          props.onSubmit()
        }
      }}
      type="password"
    />
  );
}

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  paperForm: {
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 25,
    paddingBottom: 25,
  },
  filledTextfield: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    flexBasis: 200,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    marginTop: 17,
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  buttonLogin: {
    marginRight: 1,
    "&:hover": {
      backgroundColor: "#90B134"
    }
  },
  textFieldLoginFilled: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#90B134',
      contrastText: "white" //button text white instead of black 
    },
    secondary: {
      main: '#FFFFFF',
      contrastText: '#90B134',
    }
  },
  typography: {
    button: {
      textTransform: "none",
      fontSize: 16,
      backgroundColor: 'transparent'
    }
  }
});


class LoginRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      password: "",
      passwordRepeat: "",

      register: false,
      login: true,

      openValidationDialog: false,
      dialogTitle: "",
      dialogText: "",
    };
    this.handleLoginRegisterClick = this.handleLoginRegisterClick.bind(this);
    this.handleLoginRegisterSelectionClick = this.handleLoginRegisterSelectionClick.bind(this);
    this.handleUsername = this.handleUsername.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handlePasswordRepeat = this.handlePasswordRepeat.bind(this);
    this.setTitleAndTextOfDialog = this.setTitleAndTextOfDialog.bind(this);
  }

  componentDidMount() {
    sessionStorage.setItem("previousScreen", "login");
    document.cookie = "sessionToken=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }

  handleLoginRegisterClick() {
    var cookieData;
    if (this.state.login === true) {
      var url = apiURL +  "SurveyUsers/login?include=User";
      //console.log(apiURL);
      //console.log(url);

      axios.post(url, {
        "username": this.state.username,
        "password": this.state.password
      }).then((res) => {
        /*const userData = {
          accesstoken: res.data.id,
          userID: res.data.userId,
          surveyID: res.data.surveyId,
          ttl: res.data.ttl
        }*/
        //console.log(res)
        this.props.setUserData(res.data);
        this.props.setAccessToken(res.data.id);
        cookieData = res.data;
        var username = res.data.user.username;
        sessionStorage.setItem("username", username);
        //set Userdata in redux 
      }).catch((error) => {
        //Show Error Dialog
        //console.log(error);
        if (error.response.data.error.statusCode) {
          this.setTitleAndTextOfDialog("", "Benutzername oder Passwort sind leider falsch - versuche es erneut!");
          this.setState({ openValidationDialog: true });
          this.setState({ username: "" });
          this.setState({ password: "" });
        }
      }).then(() => {
        if (cookieData !== undefined) {
          //create the cookie
          document.cookie = "sessionToken=" + JSON.stringify({ accessToken: cookieData.id, standardSurveyId: cookieData.surveyId, editSurveyId: -1, userId: cookieData.userId });
          //this.props.setUserData(userData)
          this.props.history.push('/dashboard');
        }
      });
    } else if (this.state.register === true) {
      //check if the passwords are the same
      if (this.state.username !== "") {
        if (this.state.password === this.state.passwordRepeat) {
          axios.post(apiURL+"SurveyUsers", {
            "username": this.state.username,
            "password": this.state.password
          }).then((res) => {
            axios.post(apiURL + "SurveyUsers/login?include=User", {
              "username": this.state.username,
              "password": this.state.password
            }).then((res) => {
              /*const userData = {
                accesstoken: res.data.id,
                userID: res.data.userId,
                surveyID: res.data.surveyId,
                ttl: res.data.ttl
              }*/
              this.props.setUserData(res.data);
              this.props.setAccessToken(res.data.id);
              cookieData = res.data;

              var username = res.data.user.username;
              sessionStorage.setItem("username", username);
              sessionStorage.setItem("flagRegister", "true");
            }).catch((error) => {
              //Show Error Dialog
              //console.log(error);
              if (error.response.data.error.statusCode) {
                this.setTitleAndTextOfDialog("", "Benutzername oder Passwort sind leider falsch - versuche es erneut!");
                this.setState({ openValidationDialog: true });
                this.setState({ username: "" });
                this.setState({ password: "" });
              }
            }).then(() => {
              if (cookieData !== undefined) {
                //create the cookie
                document.cookie = "sessionToken=" + JSON.stringify({ accessToken: cookieData.id, standardSurveyId: cookieData.surveyId, editSurveyId: -1, userId: cookieData.userId });
                //this.props.setUserData(userData)
                this.props.history.push('/userprofil');
              }
            });;
          }).catch((error) => {
            if (error.response.data.error.statusCode === 422) {
              this.setTitleAndTextOfDialog("", "Benutzername wurde schon einmal verwendet - versuche es noch einmal.");
              this.setState({ openValidationDialog: true });
              this.setState({ username: "" });
              this.setState({ password: "" });
              this.setState({ passwordRepeat: "" });
            }
          });
        } else {

        }
      } else {
        this.setTitleAndTextOfDialog("", "Die beiden eingegebenen Passwörter stimmen nicht überein! Bitte versuche es erneut!");
        this.setState({ openValidationDialog: true });
        this.setState({ password: "" });
        this.setState({ passwordRepeat: "" });
      }
    }
  }

  handleLoginRegisterSelectionClick() {
    this.setState(state => ({
      register: !state.register,
      login: !state.login,
    }));
  }

  handleUsername = event => {
    this.setState({ username: event.target.value });
    //console.log(event); 
  }

  handlePassword = event => {
    this.setState({ password: event.target.value });
  }

  handlePasswordRepeat = event => {
    this.setState({ passwordRepeat: event.target.value });
  }

  handleOkClick = () => {
    this.setState({ openValidationDialog: false });
  };

  setTitleAndTextOfDialog(title, text) {
    this.setState({ dialogTitle: title });
    this.setState({ dialogText: text });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Helmet>
                <meta charSet="utf-8" />
                <title>Tagebuch</title>
                <link rel="canonical" href="https://uct.labs.fhv.at/tagebuch/" />
        </Helmet>
        <div className="App">
          <div className={classes.root}>
            <AppBar position="static" style={{ backgroundColor: '#90B134' }}>
              <Toolbar>
                <Typography variant="h6" color="inherit" className={classes.grow}>
                  Wohlbefindenstagebuch
              </Typography>
              </Toolbar>
            </AppBar>
          </div>
          <MuiThemeProvider theme={theme}>
            <div className="content">
              <div className="registerLogin">
                <font color="#90B134" className="fontLoginRegister">{this.state.login ? 'Mit dieser App behältst du dein Wohlbefinden im Blick.' : 'Bitte melde dich an, um dein persönliches Tagebuch nutzen zu können. Merke dir die Zugangsdaten!'}</font>
                <p className="lineBreakerStartForm" />
                <TextField
                  id="standard-name_user"
                  label="Benutzername"
                  margin="normal"
                  value={this.state.username}
                  onChange={this.handleUsername}
                  onKeyPress={(ev) => {
                    //console.log(`Pressed keyCode ${ev.key}`);
                    if (ev.key === 'Enter') {
                      // Do code here
                      this.handleLoginRegisterClick();
                    }
                  }}
                />
                <br />
                <TextField
                  id="standard-name_password"
                  label="Passwort"
                  margin="normal"
                  value={this.state.password}
                  onChange={this.handlePassword}
                  onKeyPress={(ev) => {
                    //console.log(`Pressed keyCode ${ev.key}`);
                    if (ev.key === 'Enter') {
                      this.handleLoginRegisterClick();
                    }
                  }}
                  type="password"
                />
                <br />
                <PasswordRepeat register={this.state.register} thus={this} onSubmit={this.handleLoginRegisterClick} />
                <p className="lineBreakerStartForm" />

                <Button className={classes.button} color="primary" onClick={this.handleLoginRegisterSelectionClick}>{this.state.register ? "Abbrechen" : "Tagebuch erstellen"}</Button>
                <Fab className={classes.buttonLogin} variant="extended" type="submit" onSubmit={()=>console.log("submit")}color="primary" onClick={this.handleLoginRegisterClick}>
                  {this.state.register ? "Tagebuch erstellen" : "Anmelden"}
                </Fab>
              </div>
            </div>
            
          </MuiThemeProvider>
          {this.state.openValidationDialog ? <ResponsiveTextDialog openDialog="true" fullScreen={false} closeDialog={this.handleOkClick}
              title={this.state.dialogTitle} text={this.state.dialogText} /> : null}
          <footer className="startScreenFooter">

            
              <table align="center">
                <tbody><tr><td>
                <Link to='/imprint' className="link">Impressum</Link>
                </td></tr>
                <tr><td>
                <Link to='/privacyStatement' className="link">Datenschutzerklärung</Link></td></tr></tbody>
              </table>
           
          </footer>
        </div>
      </div >
    );
  }
}


function mapStateToProps(state) {
  return {
    userData: state
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (userData) => dispatch({ type: 'SET_USER_DATA', userData }),
    setAccessToken: (accessToken) => dispatch({ type: 'SET_ACCESSTOKEN', accessToken })
  };

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginRegister));
