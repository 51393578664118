import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#90B134',
            contrastText: "white" //button text white instead of black 
        }
    },
    typography: {
        button: {
            textTransform: "none",
            fontSize: 16,
        }
    }
});

class ResponsiveDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openDialog: false,
        };
    }


    componentDidMount() {
        this.setState({ openDialog: this.props.openDialog });
    }
    
    handleClose = () => {
        this.setState({ openDialog: false });
        this.props.closeDialog();
    };


    render() {
        //const { fullScreen } = this.props;

        return (
            <div>
                <Dialog
                    fullScreen={false}
                    open={""+this.state.openDialog === "true"}
                    onClose={this.handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.props.text}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MuiThemeProvider theme={theme}>
                            <Fab variant="extended"  autoFocus color="primary" disableRipple={true} onClick={this.handleClose}>
                                Verstanden!
                            </Fab>
                        </MuiThemeProvider>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

ResponsiveDialog.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(ResponsiveDialog);