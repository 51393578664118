import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import happy_active from '../../icons/happy(1)_active.png';
import happy_inactive from '../../icons/happy(1)_inactive.png';
import friendly_active from '../../icons/friendly(2)_active.png';
import friendly_inactive from '../../icons/friendly(2)_inactive.png';
import neutral_active from '../../icons/neutral(3)_active.png';
import neutral_inactive from '../../icons/neutral(3)_inactive.png';
import bad_active from '../../icons/bad(4)_active.png';
import bad_inactive from '../../icons/bad(4)_inactive.png';
import sad_active from '../../icons/sad(5)_active.png';
import sad_inactive from '../../icons/sad(5)_inactive.png';

const activePaths = {1:happy_active, 2:friendly_active, 3:neutral_active, 4:bad_active, 5:sad_active};
const inactivePaths = {1:happy_inactive, 2:friendly_inactive, 3:neutral_inactive, 4:bad_inactive, 5:sad_inactive};
const altNames = {1:"happy face", 2:"friendly face", 3:"neutral face", 4:"unfriendly face", 5:"sad face"};

export default function RatingItem(props) {
    let selections

    if (props.item.choices !== null) {
        if (props.useSmileys === undefined || props.useSmileys === false)  {

            selections = props.item.choices.map((choice,index) => 
                <FormControlLabel key={props.item.id+"_"+index} value={""+choice} control={<Radio color="primary" checked={""+props.item.value===""+choice}/>} label={""+choice} labelPlacement="Top"/>
            )
        } else {
            selections = props.item.choices.map((choice,index) => 
                <FormControlLabel
                    key={props.item.id+"_"+index} 
                    value={choice}
                    control={<Radio color="primary" checked={""+props.item.value===""+choice}/>}
                    label={
                        <>
                            <img src={""+props.item.value===""+choice ? activePaths[choice] : inactivePaths[choice]} className="ratingImage"  alt={altNames[choice]} />
                        </>
                    }
                    labelPlacement="top"
                />
            );
        }
    }
    return (
      <FormControl component="fieldset" error={props.item.errorText !== undefined ? true:null}>
            <div>
                  <FormLabel component="legend">{props.item.text}</FormLabel>
            </div>
            <RadioGroup
                  value={props.item.value}
                  onChange={(evt) => props.onChange(props.item,evt.target.value)} row>
                  {selections}
            </RadioGroup>
            {  props.item.errorText !== undefined?
                <FormHelperText id={"my-helper-text" + props.item.id}>{props.item.errorText}</FormHelperText>
            :<div/>
            }
      </FormControl>)

}