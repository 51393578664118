import React, { Component } from 'react';
import '../../styles/App.css';
import HeaderSurvey from '../header_footer/index_headerSurvey';
//import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
//import Radio from '@material-ui/core/Radio';
//import RadioGroup from '@material-ui/core/RadioGroup';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import FormControl from '@material-ui/core/FormControl';
//import FormLabel from '@material-ui/core/FormLabel';
import green from '@material-ui/core/colors/green';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
//import InputGroup from 'react-bootstrap/InputGroup';
//import Dropdown from 'react-bootstrap/Dropdown';
//import DropdownButton from 'react-bootstrap/DropdownButton';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
//import TextField from '@material-ui/core/TextField';
import ResponsiveTextDialog from '../Dialogs/index_responsiveTextDialog';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';

//import happy_active from '../../icons/happy(1)_active.png';
//import happy_inactive from '../../icons/happy(1)_inactive.png';
//import friendly_active from '../../icons/friendly(2)_active.png';
//import friendly_inactive from '../../icons/friendly(2)_inactive.png';
//import neutral_active from '../../icons/neutral(3)_active.png';
//import neutral_inactive from '../../icons/neutral(3)_inactive.png';
//import bad_active from '../../icons/bad(4)_active.png';
//import bad_inactive from '../../icons/bad(4)_inactive.png';
//import sad_active from '../../icons/sad(5)_active.png';
//import sad_inactive from '../../icons/sad(5)_inactive.png';

import axios from "axios";

import apiURL from '../backend/constants.js';

import Questionnaire from '../questionnaireItems/Questionnaire';


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#90B134',
            contrastText: "white" //button text white instead of black 
        }
    },
    typography: {
        button: {
            textTransform: "none",
            fontSize: 16,
        }
    },
    overrides: {
        MuiFormGroup: {
            root: {
                display:"inline"
            }
        },
        MuiFormControlLabel: {
            labelPlacementTop: {
                marginLeft:16,
                marginRight:16,
                '@media (max-width:550px)': {
                    marginLeft:8,
                    marginRight:8,
                }
            }
        },
        MuiFormControl: {
            root:{
                '@media (max-width:550px)': {
                    width:300
                }
            }
        }
    }
});

const styles = theme => ({

    button: {
        "&:hover": {
            backgroundColor: "#90B134"
        }
    },
    buttonBreak: {
        margin: theme.spacing(1),
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    group: {
        margin: `${theme.spacing(1)}px 0`,
    },
    root: {
        color: green[600],
        '&$checked': {
            color: green[500],
        },
    },
    checked: {},
});
/*
function Questions(props) {
    const questions = props.questions;
    const listOfQuestions = questions.map((question) =>
        <li>
            <MuiThemeProvider theme={theme}>

                <div id={question.questionId}>
                    <Typography variant="h6" color="textPrimary">
                        {question.question}
                    </Typography>
                    <FormControl component="fieldset">
                        <FormLabel component="legend"></FormLabel>
                        <RadioGroup
                            aria-label="position"
                            name="position"
                            onChange={props.thus.handleQuestionChange}
                            row
                        >
                            <FormControlLabel
                                value={question.questionId + " 1"}
                                control={<Radio color="primary" />}
                                label={
                                    <>
                                        <img src={question.checkedAnswer[0] ? happy_active : happy_inactive} className="profile-img" class="ratingImage"  />
                                    </>
                                }
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                value={question.questionId + " 2"}
                                control={<Radio color="primary" />}
                                label={
                                    <>
                                        <img src={question.checkedAnswer[1] ? friendly_active : friendly_inactive} className="profile-img" class="ratingImage" />
                                    </>
                                }
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                value={question.questionId + " 3"}
                                control={<Radio color="primary" />}
                                label={
                                    <>
                                        <img src={question.checkedAnswer[2] ? neutral_active : neutral_inactive} className="profile-img" class="ratingImage" />
                                    </>
                                }
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                value={question.questionId + " 4"}
                                control={<Radio color="primary" />}
                                label={
                                    <>
                                        <img src={question.checkedAnswer[3] ? bad_active : bad_inactive} className="profile-img" class="ratingImage" />
                                    </>
                                }
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                value={question.questionId + " 5"}
                                control={<Radio color="primary" />}
                                label={
                                    <>
                                        <img src={question.checkedAnswer[4] ? sad_active : sad_inactive} className="profile-img" class="ratingImage" />
                                    </>
                                }
                                labelPlacement="top"
                            />
                        </RadioGroup>
                    </FormControl>
                    <br />
                    <br />
                </div>
            </MuiThemeProvider>
        </li>
    );
    return (
        <ul>
            {listOfQuestions}
        </ul>
    );
}
*/
class SurveyQuestionnaire extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openValidationDialog: false,
            headerTitle: "",
            goToNextPage: false,

            newItemGood: "",
            selectedItemGood: "",
            addNewItemGood: false,
            selectedAnGoodItem: false,

            newItemBad: "",
            selectedItemBad: "",
            addNewItemBad: false,
            selectedAnBadItem: false,

            dropdownItemsPositiv: [],
            itemsPositiv: null,
            dropdownItemsNegativ: [],
            itemsNegativ: null,
            questions: [],
            dropdownQuestions: [],

            textPositive: "",
            textNegative: "",
            openValidationTextDialog: false,

            positivePlaceholder: "",
            positivePlaceholderActive: false,
            negativePlaceholder: "",
            negativePlaceholderActive: false,

            surveyQuestions:[],
        };
        this.textInput = React.createRef();
        this.handleOkClick = this.handleOkClick.bind(this);
        this.handleSurveyChange = this.handleSurveyChange.bind(this);
        this.validateQuestionnaire = this.validateQuestionnaire.bind(this);
        this.handleCancelSurvey = this.handleCancelSurvey.bind(this);
        this.goBackToSurvey = this.goBackToSurvey.bind(this);
        this.handleBreakSurvey = this.handleBreakSurvey.bind(this);
    }

    componentDidUpdate(prevProps) {
       
       if (this.state.location === undefined) {
            window.scrollTo(0, 0)
            this.setState({location: true})
            //this.state.location = true;
       }
          
        
    }

    componentDidMount() {
        //this.getHeaderTitle();

        //console.log("SurveyToSave received")
        //console.log(this.props.state.surveyToSave)

        let titleHeader = this.getHeaderTitle();
        var questions;
        //console.log(this.props.state);
        if (this.props.state === undefined || this.props.state.survey === undefined || this.props.state.survey.sections === undefined || this.props.state.survey.sections[0] === undefined) {
            //console.log("state not set. going back to dashboard");
            this.props.history.push('/dashboard');
            return;
        }
        if (this.props.state.survey.sections[0].id === 1) {
            questions = this.props.state.survey.sections[1].questions;
        } else if (this.props.state.survey.sections[0].id === 2) {
            questions = this.props.state.survey.sections[0].questions;
        }
        let qs = [];
        questions.forEach(q => {
            qs.push({id:q.id, text:q.text, value:q.value===undefined?"":q.value, required:q.required, choices:q.choices, shortName:q.shortName, type:q.type, order:q.order, referenceID:q.referenceID, refCondition:q.refCondition,placeholder:q.placeholder, inStats:q.inStats})
        });
        qs.sort((a,b) => {
            return a.order - b.order;
        })
        //console.log(qs)
        this.setState({surveyQuestions:qs, headerTitle:titleHeader});

/*
        //console.log(this.props.state.survey);
        for (var i = 0; i < questions.length; i++) {
            if (questions[i].type === "rating") {
                this.state.questions.push({
                    questionId: i + 1,
                    questionIdIntern: questions[i].id,
                    question: questions[i].text,
                    answer: 0,
                    checkedAnswer: [false, false, false, false, false]
                });
            } else if (questions[i].type === "pulldown") {
                if (questions[i].shortName === "Dankbarkeit") {
                    this.state.dropdownQuestions.push({ id: questions[i].id, shortName: questions[i].shortName, type: questions[i].type });
                    this.setState({ textPositive: questions[i].text });

                    var itemsPositive = questions[i].choices;
                    //itemsPositive.push("");
                    //itemsPositive.push("Sonstiges");
                    this.mapOptions(itemsPositive, "positiv");
                    this.setState({ itemsPositiv: itemsPositive });
                } else if (questions[i].shortName === "Potential") {
                    this.state.dropdownQuestions.push({ id: questions[i].id, shortName: questions[i].shortName, type: questions[i].type });
                    this.setState({ textNegative: questions[i].text });

                    var itemsNegative = questions[i].choices;
                    //itemsNegative.push("");
                    //itemsNegative.push("Sonstiges");
                    this.mapOptions(itemsNegative, "negativ");
                    this.setState({ itemsNegativ: itemsNegative });
                }
            } else if (questions[i].type === "text") {
                this.state.dropdownQuestions.push({ id: questions[i].id, shortName: questions[i].shortName, type: questions[i].type });

                if (questions[i].placeholder !== "") {
                    if (questions[i].shortName === "Dankbarkeit") {
                        this.setState({ positivePlaceholder: questions[i].placeholder });
                        this.setState({ positivePlaceholderActive: true });
                    } else if (questions[i].shortName === "Potential") {
                        this.setState({ negativePlaceholder: questions[i].placeholder });
                        this.setState({ negativePlaceholderActive: true });
                    }
                }
            }
        }*/
    }

    handleSurveyChange(item, newValue) {
        if (item === undefined) {
            console.log("invalid item passed to handleChange, ignoring...");
            return;
        }
        if (item.value === newValue) {
            console.log("no need to change");
            return;
        }
        var updatedQuestions = [];
        var currentQuestions = this.state.surveyQuestions;
        currentQuestions.forEach(element => {
            if (element.id === item.id) {
                element.value = newValue;
                if (newValue !== "" && ""+element.required === ""+true) {
                    element.errorText = undefined;
                }
            }
            updatedQuestions.push(element);
        });
        this.setState({surveyQuestions:updatedQuestions});
    }  
    

    handleOkClick = () => {
        this.setState({ openValidationDialog: false });
    };

    setTitleAndTextOfDialog(title, text) {
        this.setState({ dialogTitle: title });
        this.setState({ dialogText: text });
    }

    handleQuestionChange = event => {
        var questionAndValue = event.target.value.split(" ");
        var questionId = questionAndValue[0];

        var questionsUpdated = this.state.questions;
        questionsUpdated[questionId - 1].answer = parseInt(questionAndValue[1]);

        //Update der Check Attribute um die Bilder dynamisch zu laden
        questionsUpdated[questionId - 1].checkedAnswer = [false, false, false, false, false];
        questionsUpdated[questionId - 1].checkedAnswer[parseInt(questionAndValue[1]) - 1] = true;

        this.setState({ questions: questionsUpdated });
    };

    getHeaderTitle = () => {
        var date = new Date();

        var month = date.getMonth() + 1 //getMonth is zero based;

        var formatted = date.getDate() + "." + month + "." + date.getFullYear();

        var titleHeader = "Wie ist es dir heute gegangen?";//"Bewertung am " + formatted;

        if (this.props.state.survey != null) {
            if (this.props.state.survey.invitation != null && this.props.state.survey.invitation.title != null && this.props.state.survey.invitation.title.length > 0) {
                titleHeader = this.props.state.survey.invitation.title;
            } //else if (this.props.state.survey.title != null && this.props.state.survey.title.length > 0) {
            //    titleHeader = this.props.state.survey.title;
            //}
        }

        //this.setState({ headerTitle: titleHeader });
        return titleHeader;
    }

    saveQuestionnaire = () => {

        //TODO: profile questions not saved currently!!!!
        /*
        var surveyToSave;
        var userLoggedIn = sessionStorage.getItem("participationUser");
        if (this.props.state.survey.sections[0].id === 1) { //Profilfragen
            if (userLoggedIn === "false") {
                surveyToSave = {
                    "code": sessionStorage.getItem("accesscodeSurvey"),
                    "timeStamp": new Date().toISOString(),
                    "answers": []
                };
            } else {
                surveyToSave = this.props.state.surveyToSave;
                surveyToSave.timeStamp = new Date().toISOString();
            }
        } else if (this.props.state.survey.sections[0].id === 2) { //Befindlichkeitsfragen
            if (userLoggedIn === "false") {
                surveyToSave = {
                    "code": sessionStorage.getItem("accesscodeSurvey"),
                    "timeStamp": new Date().toISOString(),
                    "answers": []
                };
            } else {
                surveyToSave = {
                    "surveyId": this.props.state.survey.id,
                    "timeStamp": new Date().toISOString(),
                    "answers": []
                };
            }
        }*/
        var surveyToSave = this.props.state.surveyToSave;
        if (surveyToSave === undefined || surveyToSave === null) {
            surveyToSave = {
                "timeStamp": new Date().toISOString(),
                "surveyId" : this.props.state.survey.id,
                "answers": []
            }
        }
        if ((surveyToSave.answers === undefined || surveyToSave.answers === null) && this.props.state.survey.sections[0].id === 2) { //nur wenn Befindlichkeitsfragen
            surveyToSave.answers = []
        }
        if ((surveyToSave.surveyId === undefined || surveyToSave.surveyId === null)) { //nur wenn Befindlichkeitsfragen
            surveyToSave.surveyId = this.props.state.survey.id;
        }
        if ((surveyToSave.timeStamp === undefined || surveyToSave.timeStamp === null)) { //nur wenn Befindlichkeitsfragen
            surveyToSave.timeStamp = new Date().toISOString();
        }

        if ((surveyToSave.timeStamp))
        var userLoggedIn = sessionStorage.getItem("participationUser"); //survey with login (true) or with code (false)
        if (this.props.state.survey.sections[0].id === 1) { //Profilfragen
            if (userLoggedIn === "false") {
                surveyToSave.code = sessionStorage.getItem("accesscodeSurvey")
            }
        } else if (this.props.state.survey.sections[0].id === 2) { //Befindlichkeitsfragen
            if (userLoggedIn === "false") {
                surveyToSave.code = sessionStorage.getItem("accesscodeSurvey")
            } 
        }



        if (this.validateQuestionnaire()) {
            /*
            for (var i = 0; i < this.state.questions.length; i++) {
                surveyToSave.answers.push({ questionId: this.state.questions[i].questionIdIntern, value: this.state.questions[i].answer });
            }
            for (var j = 0; j < this.state.dropdownQuestions.length; j++) {
                if (this.state.dropdownQuestions[j].shortName === "Dankbarkeit") {
                    if (this.state.dropdownQuestions[j].type === "pulldown") {
                        surveyToSave.answers.push({ questionId: this.state.dropdownQuestions[j].id, value: this.state.selectedItemGood });
                    } else if (this.state.dropdownQuestions[j].type === "text") {
                        surveyToSave.answers.push({ questionId: this.state.dropdownQuestions[j].id, value: this.state.newItemGood });
                    }
                } else if (this.state.dropdownQuestions[j].shortName === "Potential") {
                    if (this.state.dropdownQuestions[j].type === "pulldown") {
                        surveyToSave.answers.push({ questionId: this.state.dropdownQuestions[j].id, value: this.state.selectedItemBad });
                    } else if (this.state.dropdownQuestions[j].type === "text") {
                        surveyToSave.answers.push({ questionId: this.state.dropdownQuestions[j].id, value: this.state.newItemBad });
                    }
                }
            }*/
            let ratingCount = 0;
            let totalScore = 0.0;
            //console.log("survey: ", surveyToSave);
            //console.log("userID: ", this.props.state.user.id)
            this.state.surveyQuestions.forEach(q => {
                //console.log("question: ", q);
                surveyToSave.answers.push({"questionId":q.id, "value":q.value===undefined?"":q.value})
                if (q.type === "rating" && q.inStats) {
                    ratingCount++;
                    totalScore+= parseInt(q.value,10);
                }
            })
            /*
            var totalScore = 0;
            for (var k = 0; k < this.state.questions.length; k++) {
                totalScore = totalScore + this.state.questions[k].answer;
            }*/
            totalScore = ratingCount > 0?totalScore / ratingCount:0;
            this.props.setTotalScore(totalScore);
            //console.log("total score");
            //console.log(totalScore);

            //surveyId + timestamp wird herausgenommen
            if (userLoggedIn === "false") {
                axios.post(apiURL + "SurveyReplies/save", {
                    "code": surveyToSave.code,
                    "timeStamp": surveyToSave.timeStamp,
                    "answers": surveyToSave.answers
                }, { headers: { Authorization: this.props.state.user.id } }).then((res) => {
                }).catch((error) => {
                    //Show Error Dialog
                    const response = error.response;
                    alert(response.data.statusText);
                    this.setState({ openValidationDialog: true });
                }).then(() => {
                    this.props.history.push('/survey/4');
                });
            } else {
                axios.post(apiURL + "SurveyReplies/save", {
                    "surveyId": surveyToSave.surveyId,
                    "timeStamp": surveyToSave.timeStamp,
                    "answers": surveyToSave.answers
                }, { headers: { Authorization: this.props.state.user.id } }).then((res) => {
                }).catch((error) => {
                    //Show Error Dialog
                    const response = error.response;
                    alert(response.data.statusText);
                    this.setState({ openValidationDialog: true });
                }).then(() => {
                    this.props.history.push('/survey/4');
                });
            }
        } else {
            this.setTitleAndTextOfDialog("Unvollständige Bewertung", "Bitte trage in jedes rot umrandete Feld etwas ein.");
            this.setState({ openValidationDialog: true });
        }
    };

    validateQuestionnaire = () => {
        let numberOfIncompleteQuestions = 0;
        this.state.surveyQuestions.forEach((q) => {
            if (q.required && (q.value === undefined || q.value === "")) {
                numberOfIncompleteQuestions++;
                if (q.required) {
                    q.errorText = "Bitte bewerten!";
                }
            }
        })
/*
        var numberOfIncompletenessQuestions = [];



        for (var i = 0; i < this.state.questions.length; i++) {
            document.getElementById(this.state.questions[i].questionId.toString()).style.border = "none";
            document.getElementById(this.state.questions[i].questionId.toString()).style.backgroundColor = "#ffffff";
            if (this.state.questions[i].answer === 0) {
                numberOfIncompletenessQuestions.push(this.state.questions.questionId);
                document.getElementById(this.state.questions[i].questionId.toString()).style.border = "solid #9c260e";
                //document.getElementById(this.state.questions[i].questionId.toString()).style.backgroundColor = "#e66245";
            }
        }
        if (numberOfIncompletenessQuestions.length === 0) {
            return true;
        } else {
            return false;
        }*/
        return numberOfIncompleteQuestions===0;
    };
/*
    handleDropdownClickGood = (evt, evtKey) => {
        this.setState({ selectedAnGoodItem: true });
        var selectedItemDropDown = this.state.itemsPositiv[evt];
        this.setState({ selectedItemGood: selectedItemDropDown });
        this.setState({ addNewItemGood: true });
    };

    handleDropdownClickBad = (evt, evtKey) => {
        this.setState({ selectedAnBadItem: true });
        var selectedItemDropDown = this.state.itemsNegativ[evt];
        this.setState({ selectedItemBad: selectedItemDropDown });
        this.setState({ addNewItemBad: true });
    };

    handleNewItemGood = event => {
        this.setState({ newItemGood: event.target.value });
    };

    handleNewItemBad = event => {
        this.setState({ newItemBad: event.target.value });
    };

    mapOptions(options, itemsMood) {
        var items = options.map((opt, i) => {
            if (opt === "") {
                return <Dropdown.Divider />;
            } else {
                return <Dropdown.Item key={i} eventKey={i}>
                    {opt}
                </Dropdown.Item>
            }
        });
        if (itemsMood === "positiv") {
            this.setState({ dropdownItemsPositiv: items });
        } else {
            this.setState({ dropdownItemsNegativ: items });
        }
    };
*/
    handleCancelSurvey() {
        if (sessionStorage.getItem("participationUser") === "true") {
            this.props.history.push('/dashboard');
        } else {
            this.props.history.push('/umfrage');
        }
    }

    goBackToSurvey() {
        this.setState({ openValidationTextDialog: false });

    }

    handleBreakSurvey() {
        this.setState({ openValidationTextDialog: true });
    }

    render() {
        const { classes } = this.props;
        //const { fullScreen } = this.props;
        //console.log("render");
        if (this.state.goToNextPage) {
            return <Redirect push to="/survey/4" />;
        } else {
            return (

                <div className="App">
                    <HeaderSurvey screenName={this.state.headerTitle} />
                    <div className="contentSurveyQuestions">
                        <div className="surveyQuestions">
                            {/*<Questions questions={this.state.questions} thus={this} />
                            <br />*/}
                            <MuiThemeProvider theme={theme}>
                                {/*
                                <Typography variant="h6" color="textPrimary">
                                    {this.state.textNegative}
                                </Typography>
                                <div className="centerDropdown">
                                    <DropdownButton
                                        onFocus={true}
                                        as={InputGroup.Prepend}
                                        variant="outline-secondary"
                                        title={this.state.selectedAnBadItem ? this.state.selectedItemBad : "bitte wählen..."}
                                        id="input-group-dropdown-1"
                                        onSelect={this.handleDropdownClickBad}
                                    //https://stackoverflow.com/questions/31509965/react-bootstrap-how-to-capture-the-value-of-dropdown-list
                                    >
                                        {this.state.dropdownItemsNegativ}
                                    </DropdownButton>
                                </div>

                                {this.state.addNewItemBad ? <TextField
                                    id="standard-name"
                                    label={this.state.negativePlaceholderActive ? this.state.negativePlaceholder : ""}
                                    margin="normal"
                                    value={this.state.newItemBad}
                                    onChange={this.handleNewItemBad}
                                    fullWidth
                                    style={{marginBottom:60}}
                                /> : <div />}
                                <br />
                                <Typography variant="h6" color="textPrimary">
                                    {this.state.textPositive}
                                </Typography>
                                <div className="centerDropdown">
                                    <DropdownButton
                                        onFocus={true}
                                        as={InputGroup.Prepend}
                                        variant="outline-secondary"
                                        title={this.state.selectedAnGoodItem ? this.state.selectedItemGood : "bitte wählen..."}
                                        id="input-group-dropdown-1"
                                        onSelect={this.handleDropdownClickGood}
                                    //https://stackoverflow.com/questions/31509965/react-bootstrap-how-to-capture-the-value-of-dropdown-list
                                    >
                                        {this.state.dropdownItemsPositiv}
                                    </DropdownButton>
                                </div>
                            

                                {this.state.addNewItemGood ? <TextField
                                    id="standard-name"
                                    label={this.state.positivePlaceholderActive ? this.state.positivePlaceholder : ""}
                                    margin="normal"
                                    value={this.state.newItemGood}
                                    onChange={this.handleNewItemGood}
                                    fullWidth
                                    style={{marginBottom:60}}
                                /> : <div />}*/}
                                <Questionnaire questions={this.state.surveyQuestions} onChange={this.handleSurveyChange} useSmileys={true}/>
                                <br />
                                <Button className={classes.buttonBreak} color="primary" onClick={this.handleBreakSurvey}>Abbrechen</Button>
                                <Fab className={classes.button} variant="extended" color="primary" onClick={this.saveQuestionnaire}>
                                    Bewertung speichern
                            </Fab>
                            </MuiThemeProvider>


                        </div>
                        {this.state.openValidationDialog ? <ResponsiveTextDialog openDialog="true" fullScreen={false} closeDialog={this.handleOkClick}
                            title={this.state.dialogTitle} text={this.state.dialogText} /> : null}

                        <Dialog
                            fullScreen={false}
                            open={this.state.openValidationTextDialog}
                            onClose={this.handleClose}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">{"Umfrage vorzeitig beenden?"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Willst du diese Bewertung wirklich vorzeitig abbrechen?
                                    Deine jetzigen Eintragungen gehen dadurch verloren.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <MuiThemeProvider theme={theme}>
                                    <Button className={classes.buttonBreak} onClick={this.handleCancelSurvey} color="primary">
                                        Bewertung Abbrechen
                                    </Button>
                                    <Fab className={classes.button} variant="extended" color="primary" onClick={this.goBackToSurvey}>
                                        Zurück zur Bewertung
                                    </Fab>
                                </MuiThemeProvider>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
            );
        }
    }
}


function mapStateToProps(state) {
    return {
        state: state
    };

}

function mapDispatchToProps(dispatch) {
    return {
        getUserData: () => dispatch({ type: 'GET_USER_DATA' }),
        setTotalScore: (totalScore) => dispatch({ type: 'SET_TOTAL_SCORE', totalScore }),
        setSurveyToSave: (surveyToSave) => dispatch({ type: 'SET_SURVEY_TO_SAVE', surveyToSave }),
    };

}

export default withMobileDialog()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SurveyQuestionnaire)));