import React, { Component } from 'react';
import '../../styles/App.css';
import HeaderApp from '../header_footer/index_headerApp';
import FooterApp from '../header_footer/index_footerApp';
import Typography from '@material-ui/core/Typography';
import axios from "axios";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import happy from '../../icons/happy(1)_active.png';
import friendly from '../../icons/friendly(2)_active.png';
import neutral from '../../icons/neutral(3)_active.png';
import bad from '../../icons/bad(4)_active.png';
import sad from '../../icons/sad(5)_active.png';

import {apiURL} from '../backend/constants.js';


class StatisticKids extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statsAvailable: false,
            positiveAverage: 0,
            negativeAverage: 0,
            positiveCorrelation: "",
            negativeCorrelation: "",

            accessTokenReload: "",
            userIdReload: -1,
            standardSurveyIdReload: -1,

            statsLoading: true,
        };
        this.computeRatingsAverage = this.computeRatingsAverage.bind(this);
    }

    componentDidMount() {
        sessionStorage.setItem("previousScreen", "statsKids");

        var statisticAnalysis;
        if (this.props.state.user.id === -1) {
            var x = document.cookie;
            if (x === "") {
                alert("Ihre Session ist leider nicht mehr aktiv - bitte loggen Sie sich erneut ein.")
                this.props.history.push('/');
            } else {

                var that = this;
                new Promise(function (resolve, reject) {

                    setTimeout(() => resolve(1), 200);

                }).then(function (result) {

                    //Splitting the cookie to get the values
                    var values = x.split("{");
                    values = values[1].split(",");
                    var accessToken = values[0].split(":");
                    var standardSurveyId = values[1].split(":");
                    var userId = values[2].split(":");

                    //store the accesToken and userId in the state 
                    that.setState({ accessTokenReload: accessToken[1].replace(/"/g, "") });
                    that.setState({ standardSurveyIdReload: standardSurveyId[1].replace(/"/g, "") });
                    that.setState({ userIdReload: userId[1].replace(/}/g, "") });
                }).then(function (result) {

                    var restCallURL = apiURL + "Surveys/userAlive";
                    axios.get(restCallURL, { headers: { Authorization: that.state.accessTokenReload } }).then((res) => {
                    }).catch((error) => {
                        //Show Error Dialog 
                        alert("Ihre Session ist leider nicht mehr aktiv - bitte loggen Sie sich erneut ein.");
                        this.props.history.push('/');
                    }).then(() => {
                        that.props.setAccessToken(that.state.accessTokenReload);
                    }).then(function (result) {
                        var restCallURL = apiURL + "Surveys/analysis";
                        axios.get(restCallURL, { headers: { Authorization: that.state.accessTokenReload } }).then((res) => {
                            statisticAnalysis = res.data;
                        }).catch((error) => {
                            //Show Error Dialog
                            alert(error)
                            that.setState({statsLoading:false});
                        }).then(() => {
                            if (statisticAnalysis.negative !== undefined && statisticAnalysis.positive !== undefined) {
                                that.setState({ positiveCorrelation: statisticAnalysis.positive.area });
                                that.setState({ negativeCorrelation: statisticAnalysis.negative.area });
                                //that.setState({ loading: false });
                                that.computeRatingsAverage(statisticAnalysis);
                            } else {
                                that.setState({ statsAvailable: false,statsLoading:false });
                                //console.log(statisticAnalysis)
                            }
                        });
                    });
                });
            }
        } else {
            var restCallURL = apiURL + "Surveys/analysis";
            axios.get(restCallURL, { headers: { Authorization: this.props.state.user.id } }).then((res) => {
                statisticAnalysis = res.data;
            }).catch((error) => {
                //Show Error Dialog
                alert(error)
                alert(error.response.statusText);
                this.setState({statsLoading:false});
            }).then(() => {
                if (statisticAnalysis.negative !== undefined && statisticAnalysis.positive !== undefined) {
                    this.setState({ positiveCorrelation: statisticAnalysis.positive.area });
                    this.setState({ negativeCorrelation: statisticAnalysis.negative.area });
                    this.computeRatingsAverage(statisticAnalysis);
                } else {
                    //this.setState({ loading: false });
                    this.setState({ statsAvailable: false,statsLoading:false });
                }
            });
        }
    };

    computeRatingsAverage(statistic) {
        var positiveRatings = statistic.positive.ratings;
        var negativeRatings = statistic.negative.ratings;

        var positiveAverageComp = 0;
        var countRatingPositive = 0;
        for (var i = 0; i < positiveRatings.length; i++) {
            countRatingPositive = countRatingPositive + (parseInt(positiveRatings[i].rating));
            positiveAverageComp = positiveAverageComp + (parseInt(positiveRatings[i].rating) * i);
        }
        positiveAverageComp = positiveAverageComp / countRatingPositive;
        this.setState({ positiveAverage: positiveAverageComp });

        var negativeAverageComp = 0;
        var countRatingNegative = 0;
        for (var j = 0; j < negativeRatings.length; j++) {
            countRatingNegative = countRatingNegative + (parseInt(negativeRatings[j].rating));
            negativeAverageComp = negativeAverageComp + (parseInt(negativeRatings[j].rating) * i);
        }

        negativeAverageComp = negativeAverageComp / countRatingNegative;
        this.setState({ negativeAverage: negativeAverageComp });
        this.setState({statsAvailable:true,statsLoading:false});
    }

    findSuitableSmiley(rating) {
        var ratingNumber = parseInt(rating);

        if (ratingNumber > 1 && ratingNumber < 1.6) {
            return <img src={happy} alt="happy face"></img>;
        } else if (ratingNumber < 2.6) {
            return <img src={friendly} alt="friendly face"></img>;
        } else if (ratingNumber > 2.59 && ratingNumber < 3.6) {
            return <img src={neutral} alt="neutral face"></img>;
        } else if (ratingNumber > 3.59 && ratingNumber < 4.6) {
            return <img src={bad} alt="unfriendly face"></img>;
        } else if (ratingNumber > 4.59) {
            return <img src={sad} alt="sad face"></img>;
        }
    }

    render() {

        //TODO: this is a hack - move to backend when settled.
        const mapping = {"meine Freunde":"mit Freunden", "mein Zuhause":"Zuhause","meine Schule":"in der Schule", "meine Freizeit":"in der Freitzeit","mein Alleinsein":"alleine"};

        const negative = mapping[this.state.negativeCorrelation] != null ? mapping[this.state.negativeCorrelation] : this.state.negativeCorrelation;
        const positive = mapping[this.state.positiveCorrelation] != null ? mapping[this.state.positiveCorrelation] : this.state.positiveCorrelation;

        return (
            <div>

                <div>
                    <HeaderApp screenName="Wohlbefindenstagebuch" />
                    <div className="statisticKids" style={{display:this.state.statsLoading?"none":!this.state.statsAvailable?"block":"grid", textAlign:!this.state.statsAvailable?"left":"center"}}>
                        {
                        this.state.statsAvailable ? <div className="contentStatisticKids">
                            <div className="correlationAnalysis">
                                <div className="typoDescription">
                                    <Typography variant="subtitle1" component="h2">
                                        Deine Bewertungen ergeben bislang folgende Zusammenhänge:
                                    </Typography>
                                </div>
                                <div className="negativeAnalysis">
                                    {this.findSuitableSmiley(this.state.negativeAverage)} <span>Es gibt vermehrt Situationen <b>{negative}</b>, die du nicht so gut findest.</span>
                                </div>
                                <div className="statisticKidsConnectionText">
                                    &amp;
                                </div>
                                <div className="positiveAnalysis">
                                    {this.findSuitableSmiley(this.state.positiveAverage)} <span>Es gibt vermehrt Situationen <b>{positive}</b>, die du gut findest.</span>
                                </div>
                                <div className="infoAnalysis">
                                    <small>{<Link to='/calculationMethod' className="link">
                                        Berechnungsverfahren
                                    </Link>}</small>
                                </div>
                            </div>
                        </div> 
                        : 
                        <div className="compute">
                            <p>Es gibt Situationen, über die man oft dankbar ist oder über die man öfter weniger glücklich ist. Hier werden die Situationen angeführt, die du am häufigsten ausgewählt hast.</p>
                            <p><b>Derzeit liegen aber zu wenig Daten vor!</b></p>
                            <p>Du kannst bei der täglichen Bewertung folgende Situationen auswählen:<br />
                                _meine Freunde<br/>
                                _mein Zuhause<br/>
                                _meine Schule<br/> 
                                _meine Freizeit <br/>
                                _mein Alleinsein
                            </p>
                            </div>
                        }
                    </div>
                    <footer className="footerStatistic">
                        <FooterApp currentScreen="statisticKids" />
                    </footer>
                </div>

            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        state: state
    };

}

function mapDispatchToProps(dispatch) {
    return {
        setAccessToken: (accessToken) => dispatch({ type: 'SET_ACCESSTOKEN', accessToken })
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticKids);