import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

export default function RadioItem(props) {
    let selections = null;

    let labelMapping = {"männlich":"Junge","weiblich":"Mädchen"};

    const mapLabel = choice => {
        if (labelMapping[choice] !== undefined) {
            return labelMapping[choice];
        } else {
            return choice;
        }
    }

    if (props.item.choices !== null) {
        selections = props.item.choices.map((choice,index) => 
            <FormControlLabel key={props.item.id+"_"+index} value={choice}  control={<Radio color="primary" checked={""+props.item.value===""+choice}/>} label={mapLabel(choice)} />
        )
    }

    return (
      <FormControl component="fieldset" style={(props.item.errorText !== undefined && props.item.errorText !== null && props.item.errorText !== "")? {borderColor:"red",borderWidth:1, color:"red"} : {}}>
            <div>
                  <FormLabel component="legend" style={(props.item.errorText !== undefined && props.item.errorText !== null && props.item.errorText !== "")? {borderColor:"red",borderWidth:1, color:"red"} : {}}>{props.item.text}</FormLabel>
            </div>
            <RadioGroup
                  value={props.item.value===undefined?"":props.item.value}
                  onChange={(evt) => props.onChange(props.item,evt.target.value)}
                  row 
                  >
                  {selections}
                  {!(props.item.value ===undefined || props.item.value==="" || props.item.value===null)?
                  <div style={{marginTop:10, marginLeft:25, fontSize:14, color:"#aaaaaa"}} onClick={(evt) => props.onChange(props.item,null)}>Auswahl Zurücksetzen</div>:<span></span>}

            </RadioGroup>
           
      </FormControl>)
}