import React from 'react';
import TextField from '@material-ui/core/TextField';

export default function NumberTextItem(props) {
      return (
       
             <TextField
                id={"standard-name_"+props.item.id}
                label={props.item.text}
                margin="normal"
                value={props.item.value}
                type="number"
                onChange={(evt) => {
                    if (!isNaN(evt.target.value)) {
                        props.onChange(props.item,""+parseFloat(evt.target.value));
                    }
                }}
                fullWidth
                error =  {props.item.errorText !== undefined ? true:null}
                helperText={props.item.errorText !== null? props.item.errorText:""}
                //required =  {""+props.item.required !== ""+true ? null:true}
                placeholder={props.item.placeholder!==undefined?props.item.placeholder:""}
                InputLabelProps={{
                  shrink: true,
                  className: "textInputLabel"
                }}
            />
      )

}