import React, { Component } from 'react';
import HeaderApp from '../header_footer/index_headerApp';
import '../../styles/App.css';
import HelpIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import axios from "axios";
import { connect } from 'react-redux';
import Fab from '@material-ui/core/Fab';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ResponsiveTextDialog from '../Dialogs/index_responsiveTextDialog';
import {apiURL} from '../backend/constants.js';

const styles = theme => ({
  button: {
      marginRight: 1,
      "&:hover": {
          backgroundColor: "#90B134"
      }
  },
  buttonHelp: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#90B134',
      contrastText: "white" //button text white instead of black 
    }
  },
  typography: {
    button: {
      textTransform: "none",
      fontSize: 16,
    }
  }
});


class AccessCheckSurveyCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codeSurvey: "",
      openHelpDialog: false,
      openValidationDialog: false,
      openValidationTextDialog: false,
      dialogTitle: "",
      dialogText: "",

      accessTokenReload: "",
      userIdReload: -1,
      standardSurveyIdReload: -1
    };
    this.handleCodeInput = this.handleCodeInput.bind(this);
    this.openHelpDialog = this.openHelpDialog.bind(this);
    this.handleOkClick = this.handleOkClick.bind(this);
    this.goToCreateOwnSurvey = this.goToCreateOwnSurvey.bind(this);
    this.setTitleAndTextOfDialog = this.setTitleAndTextOfDialog.bind(this);
  }

  componentDidMount() {
    if (this.props.state.user.id === -1) {
      var x = document.cookie;
      if (x === "") {
        alert("Ihre Session ist leider nicht mehr aktiv - bitte loggen Sie sich erneut ein.")
        this.props.history.push('/');
      } else {

        var that = this;
        new Promise(function (resolve, reject) {

          setTimeout(() => resolve(1), 100);

        }).then(function (result) {

          //Splitting the cookie to get the values
          var values = x.split("{");
          values = values[1].split(",");
          var accessToken = values[0].split(":");
          var standardSurveyId = values[1].split(":");
          var userId = values[2].split(":");

          //store the accesToken and userId in the state 
          that.setState({ accessTokenReload: accessToken[1].replace(/"/g, "") });
          that.setState({ standardSurveyIdReload: standardSurveyId[1].replace(/"/g, "") });
          that.setState({ userIdReload: userId[1].replace(/}/g, "") });
        }).then(function (result) {

          var restCallURL = apiURL + "Surveys/userAlive";
          axios.get(restCallURL, { headers: { Authorization: that.state.accessTokenReload } }).then((res) => {
          }).catch((error) => {
            //Show Error Dialog 
            this.setTitleAndTextOfDialog("", "Ihre Session ist leider nicht mehr aktiv - bitte loggen Sie sich erneut ein.");
            this.setState({ openValidationTextDialog: true });
            //alert("Ihre Session ist leider nicht mehr aktiv - bitte loggen Sie sich erneut ein.");
            this.props.history.push('/');
          }).then(() => {
            that.props.setAccessToken(that.state.accessTokenReload);
          });
        });
      }
    } else {
      this.setState({ accessTokenReload: this.props.state.user.id });
    }
  }

  handleCodeInput = event => {
    this.setState({ codeSurvey: event.target.value });
  };

  openHelpDialog = event => {
    this.setState({ openHelpDialog: true });
  };


  goToCreateOwnSurvey = () => {
    //var error = true;
    var newSurvey;
    var that = this;
    new Promise(function (resolve, reject) {

      setTimeout(() => resolve(1), 100);

    }).then(function (result) {

      if (that.state.codeSurvey !== "" && /^[ \t\r\n]*$/.test(that.state.codeSurvey) !== true) {
        axios.post(apiURL + "Surveys/generate", {
          "surveyKey": that.state.codeSurvey,
        }, { headers: { Authorization: that.state.accessTokenReload } }).then((res) => {
          //error = false;
          newSurvey = res.data;
        }).catch((error) => {
          error = true
        }).then(function (result) {
          if (newSurvey.status !== "Code not valid") {
            var x = document.cookie;
            //Splitting the cookie to get the values
            var values = x.split("{");
            values = values[1].split(",");
            var accessToken = values[0].split(":");
            var standardSurveyId = values[1].split(":");
            var userId = values[2].split(":");

            //error = false;
            document.cookie = "sessionToken=" + JSON.stringify({ accessToken: accessToken[1].replace(/"/g, ""), standardSurveyId: standardSurveyId[1].replace(/"/g, ""), editSurveyId: newSurvey.id, userId: userId[1].replace(/}/g, "") });
            that.props.setIdSurvey(newSurvey.id);

            that.props.history.push('/ownSurveyCreation');
          } else {
            that.setState({ openValidationDialog: true });
          }
        });
      } else {
        that.setTitleAndTextOfDialog("", "Du musst zuerst eine ID eingeben.");
        that.setState({ openValidationTextDialog: true });
      }
    });
  }

  handleOkClick = () => {
    this.setState({ openValidationDialog: false });
    this.setState({ openHelpDialog: false });
    this.setState({ openValidationTextDialog: false });
  };

  setTitleAndTextOfDialog(title, text) {
    this.setState({ dialogTitle: title });
    this.setState({ dialogText: text });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className="screenAccessCheck">
        <div className="headerReview">
          <HeaderApp screenName="Wohlbefindens-Umfragen" />
        </div>
        <div className="contentAccessCheck">
          <MuiThemeProvider theme={theme}>

            <div className="inlineCodeSurveySet">
              <TextField
                id="standard-name"
                label="ID für Frageset"
                margin="normal"
                value={this.state.codeSurvey}
                onChange={this.handleCodeInput}
                fullWidth

              />
              <IconButton
                aria-haspopup="true"
                onClick={this.openHelpDialog}
                color="inherit"
                className={classes.buttonHelp}
              >
                <HelpIcon color="primary" />
              </IconButton>
            </div>
            <Fab className={classes.button} variant="extended" disabled={this.state.codeSurvey.length === 0}
color="primary" onClick={this.goToCreateOwnSurvey}>
              Weiter
                  </Fab>


            <Dialog
              open={this.state.openHelpDialog}
              onClose={this.handleClose}
              aria-labelledby="responsive-dialog-title"
              fullScreen={false}
            >
              <DialogTitle id="responsive-dialog-title"></DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Für die Erstellung einer Wohlbefindens-Umfrage wird eine ID für das Set an
                  Fragen benötigt. Die ID kann bei den Verantwortlichen für die Datenverarbeitung
                  beantragt werden:
                </DialogContentText>
                <DialogContentText>
                  Prof. Dr. Guido Kempter<br/>
                  Fachhochschule Vorarlberg<br/>
                  Hochschulstraße 1, 6850 Dornbirn<br/>
                  guido.kempter@fhv.at<br/>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Fab className={classes.button} variant="extended" color="primary" onClick={this.handleOkClick} autoFocus>
                  Verstanden!
                </Fab>
              </DialogActions>
            </Dialog>

            <Dialog
              open={this.state.openValidationDialog}
              onClose={this.handleClose}
              aria-labelledby="responsive-dialog-title"
              fullScreen={false}
            >
              <DialogTitle id="responsive-dialog-title">{"Ungültiger Code"}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Der von dir eingegebene Code ist leider ungültig!
                                </DialogContentText>
                <p />
                <DialogContentText>
                  Versuche es erneut oder wenden dich an die Verantwortlichen der Datenverarbeitung.
                                </DialogContentText>
                <DialogContentText>
                  Email: guido.kempter@fhv.at
                                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Fab className={classes.button} variant="extended" color="primary" onClick={this.handleOkClick} autoFocus>
                  Verstanden!
                </Fab>
              </DialogActions>
            </Dialog>
          </MuiThemeProvider>
          {this.state.openValidationTextDialog ? <ResponsiveTextDialog openDialog="true" fullScreen={false} closeDialog={this.handleOkClick}
            title={this.state.dialogTitle} text={this.state.dialogText} /> : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    state: state
  };

}

function mapDispatchToProps(dispatch) {
  return {
    setIdSurvey: (idOfSurvey) => dispatch({ type: 'SET_ID_EDIT_ADD_SURVEY', idOfSurvey }),
    setAccessToken: (accessToken) => dispatch({ type: 'SET_ACCESSTOKEN', accessToken })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccessCheckSurveyCreation));