import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import HeaderApp from '../header_footer/index_headerApp';
import '../../styles/App.css';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
//import Radio from '@material-ui/core/Radio';
//import RadioGroup from '@material-ui/core/RadioGroup';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import FormControl from '@material-ui/core/FormControl';
//import FormLabel from '@material-ui/core/FormLabel';
//import AutocompleteCommunity from '../autocomplete/index_autocompleteCommunity';
//import AutocompleteSchool from '../autocomplete/index_autocompleteSchool';
//import AutocompleteSupply from '../autocomplete/index_autocompleteSupplies';
//import AutocompleteCitySchool from '../autocomplete/index_autocompleteCitySchool';
//import AutocompleteLanguage from '../autocomplete/index_autocompleteLanguage';
//import Autocomplete from '../autocomplete/index_autocompleteGeneric';

import { connect } from 'react-redux';
import axios from "axios";
import Typography from '@material-ui/core/Typography';
import ResponsiveTextDialog from '../Dialogs/index_responsiveTextDialog';
import Questionnaire from '../questionnaireItems/Questionnaire';

import apiURL from '../backend/constants.js';


const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  button: {
    marginRight: 1,
    "&:hover": {
      backgroundColor: "#90B134"
    },
    backgroundColor: "#90B134",
    textTransform: "none"
  },
  saveButton: {
    justifyContent: 'center',
    display: 'flex',
    textTransform:'none'
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  }
});


const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#90B134',
      contrastText: "white" //button text white instead of black 
    }
  },
  typography: {
    button: {
      textTransform: "none",
      fontSize: 16,
    }
  }
});

function updateStateFromProfile(userProfile,target) {
  //console.log(userProfile)
  //target.setState({ description: userProfile.description });
  //target.setState({ title: userProfile.title });
  //target.setState({ mode: parseInt(userProfile.mode) });
  //target.setState({ surveyId: parseInt(userProfile.id) });
/*
  target.state.choicesCitySchool = userProfile.questions[2].choices;
  target.state.choicesSchool = userProfile.questions[3].choices;
  target.state.currentChoicesSchool = [];
  target.state.choicesSuppylHome = userProfile.questions[7].choices;
  target.state.choicesCommunity = userProfile.questions[4].choices;
  target.state.choicesLanguage = userProfile.questions[5].choices;


  /*State sollte eigentlich so gesetzt werden - funktioniert aber leider nicht, daher wird er oben direkt zugewiesen.
  Andere Lösung muss gefunden werden?
  that.setState({ choicesSchool: userProfile.questions[2].choices });
  that.setState({ choicesCommunity: userProfile.questions[3].choices });
  that.setState({ choicesSuppylHome: userProfile.questions[6].choices });
*/
  //target.setState({ username: sessionStorage.getItem("username") });
/*
  if (userProfile.questions[0].value !== null) {
    target.setState({ yearOfBirth: userProfile.questions[0].value });
    target.setState({ deepCopyYearOfBirth: userProfile.questions[0].value });
  }
  if (userProfile.questions[1].value !== null) {
    target.setState({ gender: userProfile.questions[1].value });
    target.setState({ deepCopyGender: userProfile.questions[1].value });
  }
  if (userProfile.questions[2].value !== null) {
    var currentCitySchool = userProfile.questions[2].value;
    target.setState({ citySchool: currentCitySchool});
    target.setState({ deepCopyCitySchool: userProfile.questions[2].value });
    target.setState({ citySchoolFilled: true });
    
    //update list
    if (currentCitySchool != null && currentCitySchool != "") {
      var choiceList = [];
      for  (var l in target.state.choicesSchool) {
        var parts = target.state.choicesSchool[l].split("\t");
        //console.log("processing " + parts[0]);
        if (parts[0].startsWith(currentCitySchool) && parts.length > 1) {
          choiceList.push(parts[1]);
        }
      } 
      target.setState({currentChoicesSchool: choiceList});
    }
   
  }
  if (userProfile.questions[3].value !== null) {
    target.setState({ school: userProfile.questions[3].value });
    target.setState({ deepCopySchool: userProfile.questions[3].value });
    target.setState({ schoolFilled: true });
  }
  if (userProfile.questions[4].value !== null) {
    target.setState({ community: userProfile.questions[4].value });
    target.setState({ deepCopyCommunity: userProfile.questions[4].value });
    target.setState({ communityFilled: true });
  }
  if (userProfile.questions[5].value !== null) {
    target.setState({ language: userProfile.questions[5].value });
    target.setState({ deepCopyLanguage: userProfile.questions[5].value });
    target.setState({ languageFilled: true });

  }
  if (userProfile.questions[6].value !== null) {
    target.setState({ numberOfSiblings: userProfile.questions[6].value });
    target.setState({ deepCopyNumberOfSiblings: userProfile.questions[6].value });
  }
  if (userProfile.questions[7].value !== null) {
    target.setState({ supplyHome: userProfile.questions[7].value });
    target.setState({ deepCopySupplyHome: userProfile.questions[7].value });
    target.setState({ supplyFilled: true });
  }
*/
  let questions = [];
  target.unfilteredChoices = new Map();
  userProfile.questions.forEach(q => {
    questions.push({id:q.id, text:q.text, value:q.value, required:q.required, choices:q.choices, shortName:q.shortName, type:q.type, order:q.order, referenceID:q.referenceID, refCondition:q.refCondition, placeholder:q.placeholder})
    if (q.refCondition === 'filterChoices') {
      target.unfilteredChoices.set(q.id,q.choices);
    }

  });
  questions.sort((a,b) => {
    return a.order - b.order;
  })
  target.filterChoices(questions);

  //console.log(questions);
  target.setState({profileQuestions:questions,description: userProfile.description,title: userProfile.title,mode: parseInt(userProfile.mode),surveyId: parseInt(userProfile.id),username: sessionStorage.getItem("username"),dataLoaded:true });



}

class UserProfil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      /*
      school: "",
      citySchool:"",
      community: "",
      yearOfBirth: "",
      numberOfSiblings: "",
      language: "",
      gender: "",
      supplyHome: "",

      supplyFilled: false,
      schoolFilled: false,
      communityFilled: false,
      citySchoolFilled: false,
      languageFilled: false,
*/

      changes: false,
      openValidationDialog: false,
      dialogTitle: "",
      dialogText: "",
/*
      deepCopySchool: "",
      deepCopyCitySchool: "",
      deepCopyCommunity: "",
      deepCopyYearOfBirth: "",
      deepCopyGender: "",
      deepCopyLanguage: "",
      deepCopyNumberOfSiblings: "",
      deepCopySupplyHome: "",*/

      accessTokenReload: "",
      userIdReload: -1,
      standardSurveyIdReload: -1,
/*
      choicesSchool: [],
      choicesCommunity: [],
      choicesSuppylHome: [],
      choicesCitySchool:[],
      choicesLanguage:[],

      currentChoicesSchool:[],*/

      description: "",
      title: "",
      mode: -1,
      surveyId: -1,

      profileQuestions:[],

      dataLoaded: false,
    };
    this.unfilteredChoices = [];
    /*this.handleGenderChange = this.handleGenderChange.bind(this);
    this.handleYearOfBirth = this.handleYearOfBirth.bind(this);
    this.handleCommunity = this.handleCommunity.bind(this);
    this.handleSchool = this.handleSchool.bind(this);
    this.handleCitySchool = this.handleCitySchool.bind(this);
    this.handleSupply = this.handleSupply.bind(this);
    this.handleNumerOfSiblings = this.handleNumerOfSiblings.bind(this);
    this.handleLanguage = this.handleLanguage.bind(this);*/
    this.handleOkClick = this.handleOkClick.bind(this);
    //this.checkIfSomeDataHasChanged = this.checkIfSomeDataHasChanged.bind(this);
    this.setTitleAndTextOfDialog = this.setTitleAndTextOfDialog.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(item, newValue) {
    if (item === undefined) {
      //console.log("invalid item passed to handleChange, ignoring...");
      return;
    }
    if (item.value === newValue) {
      //console.log("no need to change");
      return;
    }
    var updatedQuestions = [];
    var currentQuestions = this.state.profileQuestions;
    currentQuestions.forEach(element => {
      if (element.id === item.id) {
        element.value = newValue;
      }
      else if (item.id === element.referenceID && element.refCondition === "filterChoices") {
        let choices = [];
        this.unfilteredChoices.get(element.id).forEach( ch => {
          
            var parts = ch.split("\t");
            //console.log("processing " + parts[0]);
            if (parts[0].startsWith(newValue) && parts.length > 1) {
              choices.push(parts[1]);
            }
        });
        element.choices = choices;
        element.value = "";
        //console.log(element);
      }
      updatedQuestions.push(element);
    });
    this.setState({profileQuestions:updatedQuestions});
  }

  filterChoices(questions) {
    //console.log("filter choices");
    for (const [key,value] of this.unfilteredChoices.entries()) {
      if (value !== undefined) {
         let refQuestion = null;
         questions.forEach(q => {
          if (q.id === key) {
            refQuestion = q;
          }
         })

        if (refQuestion != null && refQuestion.referenceID != null) {
          let refVal = null;
          questions.forEach(q => {
            if (q.id === refQuestion.referenceID) {
              refVal = q.value;
            }
          })
          if (refVal != null) {
            let choices = [];
            value.forEach( ch => {
              
                var parts = ch.split("\t");
                //console.log("processing " + parts[0]);
                if (parts[0].startsWith(refVal) && parts.length > 1) {
                  choices.push(parts[1]);
                }
            });
            //console.log("filtered choices: ");
            //console.log("choices");
            refQuestion.choices = choices;
          }
        }
      }
    }
  }

  componentDidMount() {
    var userProfile;
    var registerFlag = sessionStorage.getItem("flagRegister");
    var that = this;

    if (this.props.state.user.id === -1 || registerFlag === "true") {
      var x = document.cookie;
      if (x === "") {
        this.setTitleAndTextOfDialog("", "Ihre Session ist leider nicht mehr aktiv - bitte loggen Sie sich erneut ein.");
        this.setState({ openValidationDialog: true });
        this.props.history.push('/');
      } else {
          //Splitting the cookie to get the values
        var values = x.split("{");
        values = values[1].split(",");
        var accessToken = values[0].split(":")[1].replace(/"/g, "") ;
        var standardSurveyId = values[1].split(":")[1].replace(/"/g, "") ;
        var userId = values[3].split(":")[1].replace(/"/g, "") ;

        new Promise(function (resolve, reject) {

          setTimeout(() => resolve(1), 100);

        //}).then(function (result) {



          //store the accesToken and userId in the state 
          //that.setState({ accessTokenReload: accessToken[1].replace(/"/g, "") });
          //that.setState({ standardSurveyIdReload: standardSurveyId[1].replace(/"/g, "") });
          //that.setState({ userIdReload: userId[1].replace(/}/g, "") });
        }).then(function (result) {

          var restCallURL = apiURL + "Surveys/userAlive";
          axios.get(restCallURL, { headers: { Authorization: accessToken } }).then((res) => {
          }).catch((error) => {
            //Show Error Dialog 
            this.setTitleAndTextOfDialog("", "Ihre Session ist leider nicht mehr aktiv - bitte loggen Sie sich erneut ein.");
            this.setState({ openValidationDialog: true });
            //alert("Ihre Session ist leider nicht mehr aktiv - bitte loggen Sie sich erneut ein.");
            this.props.history.push('/');
          }).then(() => {
            that.props.setAccessToken(accessToken);
          }).then(function (result) {

            var restCallURL = apiURL + "Surveys/profile";
            axios.get(restCallURL, { headers: { Authorization: accessToken } }).then((res) => {
              userProfile = res.data;
              updateStateFromProfile(userProfile,that);
              that.setState({ accessTokenReload: accessToken, standardSurveyIdReload: standardSurveyId, userIdReload: userId});
            }).catch((error) => {
              //Show Error Dialog
              this.setTitleAndTextOfDialog("", "Leider haben wir derzeit Probleme beim abrufen deines Profils - Versuch es bitte erneut.");
              this.setState({ openValidationDialog: true});

              //alert(error.response.statusText);
            });//.then(function (result) {

             // updateStateFromProfile(userProfile,that);

             
           // })//.then(function (result) {
            //  that.setState({ dataLoaded: true });
            //});
          });
        });
      }
    } else {
      new Promise(function (resolve, reject) {

        setTimeout(() => resolve(1), 100);

      }).then(function (result) {

        that.setState({ accessTokenReload: that.props.state.user.id });

        updateStateFromProfile(that.props.state.userProfile,that);

      })//.then(function (result) {

       // that.setState({ dataLoaded: true });

      //});
    }
  }

  

  componentWillUnmount() {
    sessionStorage.setItem("flagRegister", "false");
  }
/*
  checkIfSomeDataHasChanged() {
    if (this.state.school !== this.state.deepCopySchool || this.state.citySchool !== this.state.deepCopyCitySchool || this.state.community !== this.state.deepCopyCommunity
      || this.state.gender !== this.state.deepCopyGender || this.state.yearOfBirth !== this.state.deepCopyYearOfBirth
      || this.state.language !== this.state.deepCopylanguage || this.state.numberOfSiblings !== this.state.deepCopyNumberOfSiblings) {
      this.setState({ changes: true });
    }
  }

  
  handleYearOfBirth = event => {
    var reg = /^\d+$/;
    if (event.target.value !== "" && reg.test(event.target.value) === false) {
      this.setState({ yearOfBirth: "" });
      this.setTitleAndTextOfDialog("", "In diesem Feld sind nur Nummerische Werte erlaubt (2000, 2001, 2002, ...)");
      this.setState({ openValidationDialog: true });
    } else {
      this.setState({ yearOfBirth: event.target.value });
    }
  }

  handleNumerOfSiblings = event => {
    var reg = /^\d+$/;
    if (event.target.value !== "" && reg.test(event.target.value) === false) {
      this.setState({ numberOfSiblings: 0 });
      this.setTitleAndTextOfDialog("", "In diesem Feld sind nur Nummerische Werte erlaubt (1, 2, 3, ...)");
      this.setState({ openValidationDialog: true });
    } else {
      this.setState({ numberOfSiblings: event.target.value });
    }
  }

  //Funktion wird bei der Komponenten "AutocompleteCommunity" mitgegeben - Lifting State up
  handleCommunity(community) {
    this.setState({ community: community });
  }

    //Funktion wird bei der Komponenten "AutocompleteCommunity" mitgegeben - Lifting State up
  handleCitySchool(citySchool) {
    if (citySchool != this.state.citySchool) {
      this.setState({ citySchool: citySchool });
      this.setState({schoolFilled: false});
      this.setState({school:""});
      if (citySchool != null) {
        var choiceList = [];
        for  (var l in this.state.choicesSchool) {
          var parts = this.state.choicesSchool[l].split("\t");
          //console.log("processing " + parts[0]);
          if (parts[0].startsWith(citySchool) && parts.length > 1) {
            choiceList.push(parts[1]);
          }
        } 
        this.setState({currentChoicesSchool: choiceList});
      }
    } else {
      //console.log("same city, nothing to do");
    }
  }

  //Funktion wird bei der Komponenten "AutocompleteSchool" mitgegeben - Lifting State up
  handleSchool(school) {
    this.setState({ school: school });
  }

  //Funktion wird bei der Komponenten "AutocompleteSupply" mitgegeben - Lifting State up
  handleSupply(supply) {
    this.setState({ supplyHome: supply });
  }

  handleGenderChange = event => {
    this.setState({ gender: event.target.value });
  };

  handleLanguage(language) {
    this.setState({ language: language });
  };*/

  handleOkClick = () => {
    this.setState({ openValidationDialog: false });
  };

  setTitleAndTextOfDialog(title, text) {
    this.setState({ dialogTitle: title, dialogText: text });
    //this.setState({ dialogText: text });
  }

  handleButtonClick = () => {
    //console.log(this.state.surveyId);
    var surveyResult = {      
      "description": this.state.description,
      "title": this.state.title,
      "mode": this.state.mode,
      "surveyId": this.state.surveyId
    }

    //generate answers
    var defaultAnswers = [];
    this.state.profileQuestions.forEach(q => {
      defaultAnswers.push({"questionId":q.id, "value":q.value===undefined?"":q.value})
    })
    var sendData = {...surveyResult, "defaultAnswers":defaultAnswers};
    axios.post(apiURL + "Surveys/saveProfile", sendData, { headers: { Authorization: this.state.accessTokenReload } }).then((res) => {
    }).catch((error) => {
      this.setTitleAndTextOfDialog("", "Beim speichern der Daten ist leider etwas schief gelaufen.");
      this.setState({ openValidationDialog: true });
      //const response = error.response;
    }).then(() => {
      this.props.history.push('/dashboard');
    });
/*
    axios.post(apiURL + "Surveys/saveProfile", {
      "description": this.state.description,
      "title": this.state.title,
      "mode": this.state.mode,
      "surveyId": this.state.surveyId,
      "defaultAnswers": [{
        "questionId": 1,
        "value": parseInt(this.state.yearOfBirth, 10)
      }, {
        "questionId": 2,
        "value": this.state.gender,
      },      {
        "questionId": 3,
        "value": this.state.citySchool
      },
      {
        "questionId": 4,
        "value": this.state.school
      },
      {
        "questionId": 5,
        "value": this.state.community
      },
      {
        "questionId": 6,
        "value": this.state.language
      },
      {
        "questionId": 7,
        "value": parseInt(this.state.numberOfSiblings, 10)
      },
      {
        "questionId": 8,
        "value": this.state.supplyHome
      }]
    }, { headers: { Authorization: this.state.accessTokenReload } }).then((res) => {
    }).catch((error) => {
      this.setTitleAndTextOfDialog("", "Beim speicher der Daten ist leider etwas schief gelaufen.");
      this.setState({ openValidationDialog: true });
      const response = error.response;
    }).then(() => {
      this.props.history.push('/dashboard');
    });*/
  };

  render() {
    const { classes } = this.props;
    //console.log("rendering profile");
    return (
      <div >
        <div className="header">
          <HeaderApp screenName="Profil" backButton={false} />
        </div>
        <div className="contentUserprofile">
          {this.state.dataLoaded ?
          /*
            <form className="formUserProfile">
              <MuiThemeProvider theme={theme}>
                
                <TextField
                  id="standard-name"
                  label="Dein Benutzername"
                  margin="normal"
                  value={this.state.username}
                  disabled="true"
                  fullWidth
                />
                <br />
                <Typography variant="body2" color="textPrimary">
                  Fragen, die du hier nicht beantwortest, werden später bei deinen Befindlichkeitsbewertungen wiederholt gestellt.
              </Typography>
                <br />

                <TextField
                  id="standard-name"
                  label="In welchem Jahr bist du geboren?"
                  margin="normal"
                  value={this.state.yearOfBirth}
                  onChange={this.handleYearOfBirth}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <br />
                <TextField
                  id="standard-name"
                  label="Mit wievielen Personen wohnst du zu Hause?"
                  margin="normal"
                  value={this.state.numberOfSiblings}
                  onChange={this.handleNumerOfSiblings}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <br />

                <Autocomplete onChangeCallback={this.handleLanguage} 
                  currentSelection={this.state.languageFilled ? this.state.language : ""} 
                  choices={this.state.choicesLanguage} 
                  question={"Was ist deine Muttersprache?"} 
                  placeholder={"Wähle deine Muttersprache..."} clearable={true}/>

                <Autocomplete onChangeCallback={this.handleSupply} 
                  currentSelection={this.state.supplyFilled? this.state.supplyHome: ""} 
                  choices={this.state.choicesSuppylHome}
                  question={"Wer versorgt dich zu Hause?"}
                  placeholder={"Bitte wähle..."} clearable={true}/>

                <Autocomplete onChangeCallback={this.handleCommunity}
                  currentSelection={this.state.communityFilled ? this.state.community :""}
                  choices = {this.state.choicesCommunity}
                  question = {"Wo wohnst du?"}
                  placeholder={"Bitte wähle..."} clearable={true} />

                <Autocomplete onChangeCallback={this.handleCitySchool}
                  currentSelection={this.state.citySchoolFilled ? this.state.citySchool : ""}
                  choices = {this.state.choicesCitySchool}
                  question = {"Wo gehst du zur Schule?"}
                  placeholder={"Bitte wähle..."} clearable={true} />

                <Autocomplete onChangeCallback={this.handleSchool}
                  currentSelection={this.state.schoolFilled ? this.state.school : ""}
                  choices = {this.state.currentChoicesSchool}
                  question = {"In welche Schule gehst du?"}
                  placeholder={"Bitte wähle..."} clearable={true} />

                <FormControl className={classes.formControl}>
                  <div>
                    <FormLabel component="legend">Bist du ein Mädchen oder ein Junge?</FormLabel>
                  </div>
                  <RadioGroup
                    aria-label="Gender"
                    name="gender1"
                    className={classes.group}
                    value={this.state.gender}
                    onChange={this.handleGenderChange}
                    row
                  >
                    <FormControlLabel value="weiblich" control={<Radio color="primary" />} label="Mädchen" />
                    <FormControlLabel value="männlich" control={<Radio color="primary" />} label="Junge" />
                  </RadioGroup>
                </FormControl>
                <br />
                <div className={classes.wrapper}>
                  <div className={classes.saveButton}>
                    <Fab className={classes.button} variant="extended" color="primary" onClick={this.handleButtonClick}>
                      Speichern
                  </Fab>
                  </div>
                </div>
                <br />
              </MuiThemeProvider>
            </form> 
            */
           <form className="formUserProfile">
              <MuiThemeProvider theme={theme}>
              <TextField
                  id="standard-name"
                  label="Dein Benutzername"
                  margin="normal"
                  value={this.state.username}
                  disabled={true}
                  fullWidth
                />
                
                <Typography variant="body2" color="textPrimary">
                  Fragen, die du hier nicht beantwortest, werden später bei deinen Befindlichkeitsbewertungen wiederholt gestellt.
              </Typography><br/>
              <Questionnaire questions={this.state.profileQuestions} onChange={this.handleChange}/>
              </MuiThemeProvider>
              <div className={classes.wrapper}>
                  <div className={classes.saveButton}>
                    <Fab className={classes.button} variant="extended" color="primary" onClick={this.handleButtonClick}>
                      Speichern
                  </Fab>
                  </div>
                </div>
            </form> 
          
            : <div></div>
          }
          {this.state.openValidationDialog ? <ResponsiveTextDialog openDialog="true" closeDialog={this.handleOkClick}
            title={this.state.dialogTitle} text={this.state.dialogText} /> : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    state: state
  };

}

function mapDispatchToProps(dispatch) {
  return {
    setUserProfile: (userProfile) => dispatch({ type: 'SET_USER_PROFILE_DATA', userProfile }),
    setAccessToken: (accessToken) => dispatch({ type: 'SET_ACCESSTOKEN', accessToken })
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserProfil));