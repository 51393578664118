import React, { Component } from 'react';
import '../../styles/App.css';
import HeaderSurvey from '../header_footer/index_headerSurvey';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Fab from '@material-ui/core/Fab';

import happy_active from '../../icons/happy(1)_active.png';
import happy_inactive from '../../icons/happy(1)_inactive.png';
import friendly_active from '../../icons/friendly(2)_active.png';
import friendly_inactive from '../../icons/friendly(2)_inactive.png';
import neutral_active from '../../icons/neutral(3)_active.png';
import neutral_inactive from '../../icons/neutral(3)_inactive.png';
import bad_active from '../../icons/bad(4)_active.png';
import bad_inactive from '../../icons/bad(4)_inactive.png';
import sad_active from '../../icons/sad(5)_active.png';
import sad_inactive from '../../icons/sad(5)_inactive.png';


const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    button: {
        "&:hover": {
            backgroundColor: "#90B134"
        }
    },
    group: {
        margin: `${theme.spacing(1)}px 0`,
    },
});

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#90B134',
            contrastText: "white" //button text white instead of black 
        }
    },
    typography: {
        button: {
            textTransform: "none",
            fontSize: 16,
        }
    }
});

class SurveyHelp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            advice: "",
            headerTitle: "",
            dayOfSurvey: ""
        };
        this.endTheSurvey = this.endTheSurvey.bind(this);
        this.findSmileyForHelp = this.findSmileyForHelp.bind(this);
    }

    componentDidMount() {
        this.getHeaderTitle();

        var totalScore = this.props.state.totalScore;
        if (totalScore < 4.05) {
            this.setState({ advice: "Bleib dran!" });
        } else {
            this.setState({ advice: "Vielleicht redest du bei nächster Gelegenheit mit einer Vertrauensperson über deine Befindlichkeit?" });
        }
        this.findSmileyForHelp();
    }

    findSmileyForHelp() {
        var totalScore = this.props.state.totalScore;
        if (totalScore < 1.5) {
            this.setState({ happyActive: true });
        } else if (totalScore >= 1.5 && totalScore < 2.5) {
            this.setState({ friendlyActive: true });
        } else if (totalScore >= 2.5 && totalScore < 3.5) {
            this.setState({ neutralActive: true });
        } else if (totalScore >= 3.5 && totalScore < 4.5) {
            this.setState({ badActive: true });
        } else if (totalScore >= 4.5) {
            this.setState({ sadActive: true });
        }
    }

    endTheSurvey() {
        if (this.props.state.accessToken !== "") {
            this.props.history.push('/dashboard')
        } else {
            //Nochmals genau alle möglichen Zustände (Loop, mehrfach gültig) aufzeichnen und dann alle abdecken.
            if (this.props.state.survey.mode === 0) {
                this.props.history.push('/umfrage')
            } else if (this.props.state.survey.mode === 1) {
                this.props.history.push('/survey/1')
            } else if (this.props.state.survey.maxCount === 0) {
                this.props.history.push('/umfrage')
            } else if (this.props.state.survey.mode === 1 && this.props.state.survey.maxCount !== 0) {
                this.props.history.push('/umfrage')
            }
        }
    }


    getHeaderTitle = () => {
        var date = new Date();

        var month = date.getMonth() + 1 //getMonth is zero based;

        var formatted = date.getDate() + "." + month + "." + date.getFullYear();

        /*if (this.props.state.user.id === -1) {
            var titleHeader = this.props.state.survey.title + " am " + formatted;
        } else {*/
        var titleHeader = "Bewertung  am " + formatted;
        //}

        this.setState({ headerTitle: titleHeader });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="App">
                <HeaderSurvey screenName={this.state.headerTitle} cancelSurvey={false} />
                <div className="contentSurveyDescription">
                    <div className="surveyInformation">
                        <font>Das farbige Smiley trifft auf deine heutige Befindlichkeit am besten zu.</font>
                        <p className="lineBreakerStartForm" />
                        <div className="surveyHelpSmileyDiagram">
                            <img src={this.state.happyActive ? happy_active : happy_inactive} className="profile-img" alt="happy face" width="40px" height="40px" style={{ marginRight: "5px" }} />
                            <img src={this.state.friendlyActive ? friendly_active : friendly_inactive} className="profile-img" alt= "friendly face" width="40px" height="40px" style={{ marginRight: "5px" }} />
                            <img src={this.state.neutralActive ? neutral_active : neutral_inactive} className="profile-img" alt="neutral face" width="40px" height="40px" style={{ marginRight: "5px" }} />
                            <img src={this.state.badActive ? bad_active : bad_inactive} className="profile-img" width="40px" alt="unfriendly face" height="40px" style={{ marginRight: "5px" }} />
                            <img src={this.state.sadActive ? sad_active : sad_inactive} className="profile-img" width="40px" alt = "sad face" height="40px" style={{ marginRight: "5px" }} />
                        </div>
                        <p className="lineBreakerStartForm" />
                        <font>{this.state.advice}</font>
                        <p className="lineBreakerStartForm" />

                        <MuiThemeProvider theme={theme}>
                            <Fab className={classes.button} variant="extended" color="primary" onClick={this.endTheSurvey}>
                                Bewertung abschließen
                            </Fab>
                        </MuiThemeProvider>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        state: state
    };

}

function mapDispatchToProps(dispatch) {
    return {
    };

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SurveyHelp));