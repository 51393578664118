import React, { Component } from 'react';
import '../../styles/App.css';
import RatingSkala from '../formalities/DSGVO.png';
import HeaderFormalities from '../header_footer/index_headerFormailites';


class PrivacyStatement extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div>
                <HeaderFormalities screenName="Datenschutzerklärung" previousScreen="login" backButton="true" />
                { sessionStorage.getItem("previousScreen") === "withoutLogin" ? //Umfrage
                    <div className="privacyStatement">
                
                        <p><strong>Was geschieht mit deinen Antworten?</strong></p>
                    
                        <p>In dieser Umfrage gibst du Auskunft darüber, wie es dir geht. Als Antwortmöglichkeit stehen dir pro Frage fünf Smileys zur Verfügung:</p>
                    
                        <p><img src={RatingSkala} className="dsgvoInfo" alt="rating smiley" /></p>
                        
                    
                        <p>Deine Antworten werden an der Fachhochschule Vorarlberg gespeichert. Dort kann nicht festgestellt werden, dass es sich um deine Daten handelt. </p>
                        
                        <p>Wir wollen feststellen was getan werden kann, damit sich Menschen wie du in Vorarlberg wohl fühlen. Wenn du mehr über diese Umfrage wissen willst, dann frage bitte die erwachsene Person, von der du diesen Zugang erhalten hast.</p>
                        
                        <p><strong>Datenschutzerkl&auml;rung</strong></p>
                        
                        <p>Mit dieser Umfrage werden Angaben über das Wohlbefinden und über allgemeine Personenmerkmale erfasst, die nicht oder nur mit einem unverhältnismäßig großen Aufwand einer bestimmten Person zugeordnet werden können.</p>
                        
                        <p>Es handelt sich um anonyme Daten, die von der FH Vorarlberg statistisch ausgewertet werden, um Aussagen über das Wohlbefinden von Gruppen mit allgemeinen Bevölkerungsmerkmalen (z.B. Alter, Wohnort) zu treffen.</p>

                        <p>Anhand der Analyseergebnisse werden Maßnahmen zur Verbesserung der Befindlichkeit von spezifischen Bevölkerungsgruppen abgeleitet, die durch die abgefragten Personenmerkmale definiert werden können.</p>
                                            
                        <p>Alle anonymisierten Daten sind auf einem Speichersystem der FH Vorarlberg gespeichert. Für das Speichersystem werden alle erforderlichen Maßnahmen zur Gewährleistung der Sicherheit nach Art 32 DSGVO ergriffen.</p>
                        
                        <p>Für den unwahrscheinlichen Fall, dass Daten Ihnen zugeordnet werden können, stehen Ihnen alle Betroffenenrechte laut DSGVO zu. Das heißt, Sie können eine Bestätigung darüber verlangen, wie wir Ihre Daten verarbeiten. Und Sie haben das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung, ein Widerspruchsrecht gegen die Verarbeitung sowie ein Recht auf Datenübertragung.</p>
                        
                        <p><strong>Verantwortlich f&uuml;r die Datenverarbeitung</strong><br/>
                        <p>Fachhochschule Vorarlberg GmbH, Campus V<br/>
                        Hochschulstra&szlig;e 1, 6850 Dornbirn<br/>
                        info@fhv.at, +43 5572 792-0<br/></p> </p>
                    
                    </div>
                : //Tagebuch
                    <div className="privacyStatement">
                        <p><strong>Was geschieht mit deinen Antworten?</strong></p>
                    
                    <p>Mit diesem Tagebuch hältst du fest, wie es dir geht. Als Antwortmöglichkeit stehen dir fünf Smileys pro Frage zur Verfügung:</p>
                
                    <p><img src={RatingSkala} className="dsgvoInfo" alt="rating smiley" /></p>
                    
                
                    <p>Deine Antworten werden an der Fachhochschule Vorarlberg gespeichert. Dort kann nicht festgestellt werden, dass es sich um deine Daten handelt. Wir können deshalb auch nicht deine Zugangsdaten zurücksetzen. Bewahre also Benutzername und Passwort zum Tagebuch sorgsam auf!</p>
                    
                    <p>Wir wollen feststellen was getan werden kann, damit sich Menschen wie du in Vorarlberg wohl fühlen. Wenn du mehr über diese Umfrage wissen willst, dann frage bitte die erwachsene Person, von der du diesen Zugang erhalten hast.</p>
                    
                    <p><strong>Datenschutzerkl&auml;rung</strong></p>
                    
                    <p>Mit diesem Tagebuch werden Angaben über das Wohlbefinden und über allgemeine Personenmerkmale erfasst, die nicht oder nur mit einem unverhältnismäßig großen Aufwand einer bestimmten Person zugeordnet werden können.</p>
                    
                    <p>Es handelt sich um anonyme Daten, die von der FH Vorarlberg statistisch ausgewertet werden, um Aussagen über das Wohlbefinden von Gruppen mit allgemeinen Bevölkerungsmerkmalen (z.B. Alter, Wohnort) zu treffen. </p>

                    <p>Anhand der Analyseergebnisse werden Maßnahmen zur Verbesserung der Befindlichkeit von spezifischen Bevölkerungsgruppen abgeleitet, die durch die abgefragten Personenmerkmale definiert werden können.</p>
                                        
                    <p>Alle anonymisierten Daten sind auf einem Speichersystem der FH Vorarlberg gespeichert. Für das Speichersystem werden alle erforderlichen Maßnahmen zur Gewährleistung der Sicherheit nach Art 32 DSGVO ergriffen.</p>
                    
                    <p>Für den unwahrscheinlichen Fall, dass Daten Ihnen zugeordnet werden können, stehen Ihnen alle Betroffenenrechte laut DSGVO zu. Das heißt, Sie können eine Bestätigung darüber verlangen, wie wir Ihre Daten verarbeiten. Und Sie haben das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung, ein Widerspruchsrecht gegen die Verarbeitung sowie ein Recht auf Datenübertragung.</p>
                  
                    <p><strong>Verantwortlich f&uuml;r die Datenverarbeitung</strong><br/>
                    <p>Fachhochschule Vorarlberg GmbH, Campus V<br/>
                    Hochschulstra&szlig;e 1, 6850 Dornbirn<br/>
                    info@fhv.at, +43 5572 792-0<br/></p> </p>
                    </div>
                }
            </div >
        );
    }
}

export default PrivacyStatement;
