import React, { Component } from 'react';
import '../../styles/App.css';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import axios from "axios";
import { connect } from 'react-redux';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import {apiURL} from '../backend/constants.js';


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  button: {
    marginLeft: -12,
    marginRight: 20,
    "&:hover": {
      backgroundColor: "#90B134 !important"
    }
  },
  selected: {
    backgroundColor: "transparent !important",
    color: "#90B134",
    fontWeight: 600
  }
});


class HeaderApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: true,
      anchorEl: null,
      logout: false,

      backToSurveyDetails: false,

      dashboardSelected: true,
      profileSelected: false,
      overviewSelected: false,
      logoutSelected: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);

  }

  componentDidMount() {
    if (this.props.screenName !== "Wohlbefindenstagebuch" && this.props.screenName !== "Profil" && this.props.screenName !== "Umfragen") {
      this.setState({ dashboardSelected: false, profileSelected: false, overviewSelected: false });
    } else if (this.props.screenName === "Wohlbefindenstagebuch") {
      this.setState({ dashboardSelected: true, profileSelected: false, overviewSelected: false });
    } else if (this.props.screenName === "Profil") {
      this.setState({ dashboardSelected: false, profileSelected: true, overviewSelected: false });
    } else if (this.props.screenName === "Umfragen") {
      this.setState({ dashboardSelected: false, profileSelected: false, overviewSelected: true });
    }
  }

  handleChange = event => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleBack = () => {
    this.setState({ backToSurveyDetails: true });
  }

  handleLogout = () => {
    var x = document.cookie;

    var values = x.split("{");
    values = values[1].split(",");
    var accessToken = values[0].split(":");
    var accessTokenValue = accessToken[1].replace(/"/g, "");

    //alert(accessTokenValue)

    var headers = {
      'Content-Type': 'application/json',
      'Authorization': accessTokenValue
    }
    axios.post(apiURL + "SurveyUsers/logout", { headers }).then((res) => {
      this.setState({ logout: true });
    }).catch((error) => {
      //Show Error Dialog
      alert(JSON.stringify(error.response));
    });
  };

  deleteCookie = function (name) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    alert(document.cookie)
  };

  render() {
    const { classes } = this.props;
    const { auth, anchorEl } = this.state;
    const open = Boolean(anchorEl);

    if (this.state.logout) {
      return <Redirect push to="/" />;
    } else if (this.state.backToDashboard) {
      return <Redirect push to="/dashboard" />;
    } else if (this.state.backToSurveyDetails) {
      return <Redirect push to="/ownSurveyCreation" />;
    } else {
      return (
        <div>
          <div className="App">
            <div className={classes.root}>
              <AppBar style={{ backgroundColor: '#90B134', position: "fixed" }}>
                <Toolbar>
                  {this.props.backButton ?
                    <IconButton className={classes.button} color="inherit" aria-label="Menu" onClick={this.handleBack}>
                      <BackIcon />
                    </IconButton> : null}
                  <Typography variant="h6" color="inherit" className={classes.grow}>
                    {this.props.screenName}
                  </Typography>
                  {auth && (
                    <div>
                      <IconButton
                        aria-owns={open ? 'menu-appbar' : undefined}
                        aria-haspopup="true"
                        onClick={this.handleMenu}
                        color="inherit"
                      >
                        <MenuIcon />
                      </IconButton>
                      <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={open}
                        onClose={this.handleClose}
                      >
                        {/*https://stackoverflow.com/questions/32106513/material-ui-menu-using-routes*/}
                        <Link to='/dashboard' style={{ textDecoration: 'none', color: '#000000' }}>
                          <MenuItem onClick={this.handleClose} selected={this.state.dashboardSelected} classes={{ selected: classes.selected }}>Tagebuch</MenuItem>
                        </Link>
                        <Link to='/userprofil' style={{ textDecoration: 'none', color: '#000000' }}>
                          <MenuItem onClick={this.handleClose} selected={this.state.profileSelected} classes={{ selected: classes.selected }}>Profil</MenuItem>
                        </Link>
                        <Link to='/overviewOwnSurvey' style={{ textDecoration: 'none', color: '#000000' }}>
                          <MenuItem onClick={this.handleClose} selected={this.state.overviewSelected} classes={{ selected: classes.selected }}>Umfragen</MenuItem>
                        </Link>
                        <Link to='/' style={{ textDecoration: 'none', color: '#000000' }}>
                          <MenuItem onClick={this.handleClose} selected={this.state.logoutSelected} classes={{ selected: classes.selected }}>Abmelden</MenuItem>
                        </Link>
                      </Menu>
                    </div>
                  )}
                </Toolbar>
              </AppBar>
            </div>
          </div>
        </div >
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    state: state
  };

}

function mapDispatchToProps(dispatch) {
  return {
    getUserData: () => dispatch({ type: 'GET_USER_DATA' }),
  };

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HeaderApp));
