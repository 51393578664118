import React, {Component} from 'react'
import '../../styles/App.css';


class NotFound extends Component {
    render() {
      return (
        <div className="notFound"> 
            <h1>Unter der eingegebenen URL ist leider keine Seite zu finden.</h1>
        </div>
      );
    }
  }
  
  export default NotFound;