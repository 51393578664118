import React, { Component } from 'react';
import '../../styles/App.css';
import PropTypes from 'prop-types';
import HeaderSurvey from '../header_footer/index_headerSurvey';
import Typography from '@material-ui/core/Typography';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
//import TextField from '@material-ui/core/TextField';
//import Radio from '@material-ui/core/Radio';
//import RadioGroup from '@material-ui/core/RadioGroup';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import FormControl from '@material-ui/core/FormControl';
//import FormLabel from '@material-ui/core/FormLabel';
import Fab from '@material-ui/core/Fab';
//import AutocompleteCommunity from '../autocomplete/index_autocompleteCommunity';
//import AutocompleteSchool from '../autocomplete/index_autocompleteSchool';
//mport AutocompleteSupply from '../autocomplete/index_autocompleteSupplies';
//import Autocomplete from '../autocomplete/index_autocompleteGeneric';
import { connect } from 'react-redux';
import ResponsiveTextDialog from '../Dialogs/index_responsiveTextDialog';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Questionnaire from '../questionnaireItems/Questionnaire';


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#90B134',
            contrastText: "white" //button text white instead of black 
        }
    },
    typography: {
        button: {
            textTransform: "none",
            fontSize: 16,
        }
    }
});

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    button: {
        "&:hover": {
            backgroundColor: "#90B134"
        }
    },
    buttonBreak: {
        margin: theme.spacing(1),
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
});

class SurveyAttendee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            headerTitle: "",
            loggedInUser: false,
/*
            gender: "",
            yearOfBirth: "",
            numberOfSiblings: "",
            supplyHome: "",
            school: "",
            citySchool: "",
            community: "",
            language: "",

            yearOfBirthStillToBeFilledIn: false,
            numberOfSiblingsStillToBeFilledIn: false,
            languageStillToBeFilledIn: false,
            supplyHomeStillToBeFilledIn: false,
            communityStillToBeFilledIn: false,
            schoolStillToBeFilledIn: false,
            citySchoolStillToBeFilledIn: false,
            genderStillToBeFilledIn: false,

            choicesSchool: [],
            choicesCommunity: [],
            choicesSuppylHome: [],
            choicesCitySchool: [],
            currentChoicesSchool: [],
            choicesLanguage:[],*/

            openValidationDialog: false,
            dialogTitle: "",
            dialogText: "",
            openValidationTextDialog: false,

            //loadedProfileQuestions: {},

            profileQuestions: []
        };
        /*
        this.handleGenderChange = this.handleGenderChange.bind(this);
        this.handleYearOfBirth = this.handleYearOfBirth.bind(this);
        this.handleNumerOfSiblings = this.handleNumerOfSiblings.bind(this);
        this.handleCommunity = this.handleCommunity.bind(this);
        this.handleSchool = this.handleSchool.bind(this);
        this.handleCitySchool = this.handleCitySchool.bind(this);
        this.handleSupply = this.handleSupply.bind(this);
        this.handleLanguage = this.handleLanguage.bind(this);*/
        this.handleOkClick = this.handleOkClick.bind(this);
        this.handleProfileChange = this.handleProfileChange.bind(this);
        this.saveProfileQuestions = this.saveProfileQuestions.bind(this);
        this.handleCancelSurvey = this.handleCancelSurvey.bind(this);
        this.goBackToSurvey = this.goBackToSurvey.bind(this);
        this.handleBreakSurvey = this.handleBreakSurvey.bind(this);
        this.unfilteredChoices = [];
    }

    componentDidMount() {
        let headerTitle = this.getHeaderTitle();

        if (this.props.state === undefined || this.props.state.survey === undefined || this.props.state.survey.sections === undefined || this.props.state.survey.sections[0] === undefined) {
            console.log("state not set. going back to dashboard");
            this.props.history.push('/dashboard');
            return;
        }

        let profileSection = this.props.state.survey.sections[0];
        //this.setState({ loadedProfileQuestions: this.props.state.survey.sections[0] });

        //console.log("missing questions");
        //console.log(profileSection);
        //Hier muss gewartet werden wie die endgültige Struktur der Fragen aussehen wird. Dann können die IDs zugeordnet werden.
        if (profileSection.id === 1) {

            //let hasCitySchoolQuestion = false;
            let questions = [];
            this.unfilteredChoices = new Map();
            profileSection.questions.forEach(q => {
                questions.push({id:q.id, text:q.text, value:"", required:q.required, choices:q.choices, shortName:q.shortName, type:q.type, order:q.order, referenceID:q.referenceID, refCondition:q.refCondition, placeholder:q.placeholder})
                if (q.refCondition === 'filterChoices') {
                    this.unfilteredChoices.set(q.id,q.choices);
                }
            });
            questions.sort((a,b) => {
                return a.order - b.order;
            })
            this.filterChoices(questions);
            //console.log(questions);
            this.setState({profileQuestions:questions, headerTitle:headerTitle, userLoggedIn:(this.props.state.user.id > -1)});
/*
            for (var i = 0; i < profileQuestions.questions.length; i++) {
                if (profileQuestions.questions[i].id === 1) {
                    this.setState({ yearOfBirthStillToBeFilledIn: true });
                } else if (profileQuestions.questions[i].id === 2) {
                    this.setState({ genderStillToBeFilledIn: true });
                } else if (profileQuestions.questions[i].id === 3) {
                    this.state.choicesCitySchool = profileQuestions.questions[i].choices;
                    this.setState({ citySchoolStillToBeFilledIn: true });
                    hasCitySchoolQuestion = true;
                } else if (profileQuestions.questions[i].id === 4) {
                    this.state.choicesSchool = profileQuestions.questions[i].choices;
                    if (!hasCitySchoolQuestion) {
                        var choiceList = [];
                        for  (var l in this.state.choicesSchool) {
                            var parts = this.state.choicesSchool[l].split("\t");
                            //console.log("processing " + parts[0]);
                            choiceList.push(parts[1]);
                        } 
                        this.setState({currentChoicesSchool: choiceList});
                    }
                    this.setState({ schoolStillToBeFilledIn: true });
                } else if (profileQuestions.questions[i].id === 5) {
                    this.state.choicesCommunity = profileQuestions.questions[i].choices;
                    this.setState({ communityStillToBeFilledIn: true });
                } else if (profileQuestions.questions[i].id === 6) {
                    this.setState({ languageStillToBeFilledIn: true });
                    this.state.choicesLanguage = profileQuestions.questions[i].choices;
                } else if (profileQuestions.questions[i].id === 7) {
                    this.setState({ numberOfSiblingsStillToBeFilledIn: true });
                } else if (profileQuestions.questions[i].id === 8) {
                    this.state.choicesSuppylHome = profileQuestions.questions[i].choices;
                    this.setState({ supplyHomeStillToBeFilledIn: true });
                }
            }*/
        } else {
            this.props.history.push('/survey/3');
        }
    }

    handleProfileChange(item, newValue) {
        if (item === undefined) {
          console.log("invalid item passed to handleChange, ignoring...");
          return;
        }
        if (item.value === newValue) {
          console.log("no need to change");
          return;
        }
        var updatedQuestions = [];
        var currentQuestions = this.state.profileQuestions;
        currentQuestions.forEach(element => {
          if (element.id === item.id) {
            element.value = newValue;
          }
          else if (item.id === element.referenceID && element.refCondition === "filterChoices") {
            let choices = [];
            this.unfilteredChoices.get(element.id).forEach( ch => {
              
                var parts = ch.split("\t");
                //console.log("processing " + parts[0]);
                if (parts[0].startsWith(newValue) && parts.length > 1) {
                  choices.push(parts[1]);
                }
            });
            element.choices = choices;
            element.value = "";
            //console.log(element);
          }
          updatedQuestions.push(element);
        });
        this.setState({profileQuestions:updatedQuestions});
    }

    filterChoices(questions) {
        //console.log(questions);
        for (const [key,value] of this.unfilteredChoices.entries()) {
          if (value !== undefined) {
             let refQuestion = null;
             questions.forEach(q => {
              if (q.id === key) {
                refQuestion = q;
              }
             })
    
            if (refQuestion !== null && refQuestion.referenceID !== null) {
              let refVal = null;
              questions.forEach(q => {
                if (q.id === refQuestion.referenceID) {
                  refVal = q.value;
                }
              })
              //if (refVal !== null) {
                let choices = [];
                value.forEach( ch => {
                  
                    var parts = ch.split("\t");
                    //console.log("processing " + parts[0]);

                    //if no ref value is given trim the filter criterium anyway.
                    if ((refVal===null || parts[0].startsWith(refVal)) && parts.length > 1) {
                      choices.push(parts[1]);
                    }
                });
                refQuestion.choices = choices;
              //}
            }
          }
        }
    }
/*
    handleYearOfBirth = event => {
        var reg = /^\d+$/;
        if (event.target.value !== "" && reg.test(event.target.value) === false) {
            this.setState({ yearOfBirth: "" });
            this.setTitleAndTextOfDialog("", "In diesem Feld sind nur Nummerische Werte erlaubt (2000, 2001, 2002, ...)");
            this.setState({ openValidationDialog: true });
        } else {
            this.setState({ yearOfBirth: event.target.value });
        }
    }

    handleNumerOfSiblings = event => {
        var reg = /^\d+$/;
        if (event.target.value !== "" && reg.test(event.target.value) === false) {
            this.setState({ numberOfSiblings: 0 });
            this.setTitleAndTextOfDialog("", "In diesem Feld sind nur Nummerische Werte erlaubt (1, 2, 3, ...)");
            this.setState({ openValidationDialog: true });
        } else {
            this.setState({ numberOfSiblings: event.target.value });
        }
    }

    //Funktion wird bei der Komponenten "AutocompleteCommunity" mitgegeben - Lifting State up
    handleCommunity(community) {
        this.setState({ community: community });
    }

    //Funktion wird bei der Komponenten "AutocompleteSchool" mitgegeben - Lifting State up
    handleSchool(school) {
        this.setState({ school: school });
    }

    //Funktion wird bei der Komponenten "AutocompleteSchool" mitgegeben - Lifting State up
    handleCitySchool(citySchool) {
        this.setState({ citySchool: citySchool });
        if (citySchool != null) {
            var choiceList = [];
            for  (var l in this.state.choicesSchool) {
              var parts = this.state.choicesSchool[l].split("\t");
              //console.log("processing " + parts[0]);
              if (parts[0].startsWith(citySchool) && parts.length > 1) {
                choiceList.push(parts[1]);
              }
            } 
            this.setState({currentChoicesSchool: choiceList});
        }  
    }

    //Funktion wird bei der Komponenten "AutocompleteSchool" mitgegeben - Lifting State up
    handleSupply(supply) {
        this.setState({ supplyHome: supply });
    }

    handleGenderChange = event => {
        this.setState({ gender: event.target.value });
    };

    handleLanguage(language) {
        this.setState({ language: language });
    };*/

    handleOkClick = () => {
        this.setState({ openValidationDialog: false });
    };

    saveProfileQuestions() {
        var surveyToSave = {
            "surveyId": this.props.state.survey.id,
            "timeStamp": new Date().toISOString(),
            "answers": []
        };
        
        var inputValid = true;
        /*
        if (this.state.yearOfBirthStillToBeFilledIn) {
            if (this.state.yearOfBirth === "") {
                inputValid = false;
            } else {
                surveyToSave.answers.push({ questionId: 1, value: this.state.yearOfBirth });
            }
        }
        if (this.state.genderStillToBeFilledIn && inputValid) {
            if (this.state.gender === "") {
                inputValid = false;
            } else {
                surveyToSave.answers.push({ questionId: 2, value: this.state.gender });
            }
        }
        if (this.state.citySchoolStillToBeFilledIn && inputValid) {
            if (this.state.citySchool === "") {
                inputValid = false;
            } else {
                surveyToSave.answers.push({ questionId: 3, value: this.state.citySchool });
            }
        }
        if (this.state.schoolStillToBeFilledIn && inputValid) {
            if (this.state.school === "") {
                inputValid = false;
            } else {
                surveyToSave.answers.push({ questionId: 4, value: this.state.school });
            }
        }
        if (this.state.communityStillToBeFilledIn && inputValid) {
            if (this.state.community === "") {
                inputValid = false;
            } else {
                surveyToSave.answers.push({ questionId: 5, value: this.state.community });
            }
        }
        if (this.state.languageStillToBeFilledIn && inputValid) {
            if (this.state.language === "") {
                inputValid = false;
            } else {
                surveyToSave.answers.push({ questionId: 6, value: this.state.language });
            }
        }
        if (this.state.numberOfSiblingsStillToBeFilledIn && inputValid) {
            if (this.state.numberOfSiblings === "") {
                inputValid = false;
            } else {
                surveyToSave.answers.push({ questionId: 7, value: parseInt(this.state.numberOfSiblings, 10) });
            }
        }
        if (this.state.supplyHomeStillToBeFilledIn && inputValid) {
            if (this.state.supplyHome === "") {
                inputValid = false;
            } else {
                surveyToSave.answers.push({ questionId: 8, value: this.state.supplyHome });
            }
        }*/

        this.state.profileQuestions.forEach((q) => {
            if (q.value !== undefined && q.value !== null && q.value !== "" ) {
                surveyToSave.answers.push({questionId:q.id, value: q.value})
            } else {
                if (q.required) {
                    inputValid = false; 
                    q.errorText = "Bitte ausfüllen";
                }
            }
        });

        if (inputValid === false) {
            this.setTitleAndTextOfDialog("", "Bitte trage in alle Felder etwas ein.");
            this.setState({ openValidationDialog: true });
            surveyToSave.answers = [];
            return false;
        } else {
            this.props.setSurveyToSave(surveyToSave);
            this.props.history.push('/survey/3');
        }
    };

    setTitleAndTextOfDialog(title, text) {
        this.setState({ dialogTitle: title });
        this.setState({ dialogText: text });
    }

    getHeaderTitle = () => {
        var date = new Date();

        var month = date.getMonth() + 1 //getMonth is zero based;

        var formatted = date.getDate() + "." + month + "." + date.getFullYear();

        var titleHeader;
        if (this.props.state.user.id === -1) {
            titleHeader = this.props.state.survey.title;
        } else {
            titleHeader = "Offene Profilfragen";//"Bewertung am " + formatted;
            //this.setState({ loggedInUser: true });
        }

        //this.setState({ headerTitle: titleHeader });
        return titleHeader;
    }

    handleCancelSurvey() {
        if (sessionStorage.getItem("participationUser") === "true") {
            this.props.history.push('/dashboard');
        } else {
            this.props.history.push('/umfrage');
        }
    }

    goBackToSurvey() {
        this.setState({ openValidationTextDialog: false });

    }

    handleBreakSurvey() {
        this.setState({ openValidationTextDialog: true });
    }

    render() {
        const { classes } = this.props;
        const { fullScreen } = this.props;
        return (
            <div className="App">
                <HeaderSurvey screenName={this.state.headerTitle} />
                <div className="contentSurveyDescription">
                    <div className="surveyInformation">
                        <Typography variant="subtitle1" className={classes.grow} color="textPrimary">
                            {this.state.loggedInUser ? "Folgende Fragen wurden im Profil nicht beantwortet." : "Bevor es mit der Bewertung losgehen kann, kommen noch ein paar Fragen zu deiner Person."}
                        </Typography>
                        <br />
                        <MuiThemeProvider theme={theme}> {/*
                            {this.state.yearOfBirthStillToBeFilledIn ? <React.Fragment>
                                <TextField
                                    id="standard-name"
                                    label="In welchem Jahr bist du geboren?"
                                    margin="normal"
                                    value={this.state.yearOfBirth}
                                    onChange={this.handleYearOfBirth}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <br />
                            </React.Fragment> : null}
                            {this.state.numberOfSiblingsStillToBeFilledIn ? <React.Fragment>
                                <TextField
                                    id="standard-name"
                                    label="Mit wievielen Personen wohnst du zu Hause?"
                                    margin="normal"
                                    value={this.state.numberOfSiblings}
                                    onChange={this.handleNumerOfSiblings}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <br />
                            </React.Fragment> : null}
                            {this.state.languageStillToBeFilledIn ? 
                                <React.Fragment>
                                <Autocomplete onChangeCallback={this.handleLanguage} 
                                    choices={this.state.choicesLanguage} 
                                    question={"Was ist deine Muttersprache?"} 
                                    placeholder={"Wähle deine Muttersprache..."}/>
                                </React.Fragment>: null}
                            {this.state.supplyHomeStillToBeFilledIn ?
                                <Autocomplete onChangeCallback={this.handleSupply} question={"Wer versorgt dich zu Hause?"} choices={this.state.choicesSuppylHome} /> : null}
                            {this.state.communityStillToBeFilledIn ? <Autocomplete onChangeCallback={this.handleCommunity} question={"Wo wohnst du?"} choices={this.state.choicesCommunity} /> : null}
                            {this.state.citySchoolStillToBeFilledIn ? <Autocomplete onChangeCallback={this.handleCitySchool} question={"Wo gehst du zur Schule?"} choices={this.state.choicesCitySchool} /> : null}
                            {this.state.schoolStillToBeFilledIn ? <Autocomplete onChangeCallback={this.handleSchool} question={"In welche Schule gehst du?"} choices={this.state.currentChoicesSchool} /> : null}
                            {this.state.genderStillToBeFilledIn ? <FormControl component="fieldset" className={classes.formControl}>
                                <div>
                                    <FormLabel component="legend">Bist du ein Mädchen oder ein Junge?</FormLabel>
                                </div>
                                <RadioGroup
                                    aria-label="gender"
                                    name="gender1"
                                    className={classes.group}
                                    value={this.state.gender}
                                    onChange={this.handleGenderChange}
                                >
                                    <FormControlLabel value="weiblich" control={<Radio color="primary" />} label="Weiblich" />
                                    <FormControlLabel value="männlich" control={<Radio color="primary" />} label="Männlich" />
                                </RadioGroup>
                            </FormControl> : null}
                            <br />
                            */}
                            <Questionnaire questions={this.state.profileQuestions} onChange={this.handleProfileChange}/>
                            <br />
                            <Button className={classes.buttonBreak} color="primary" onClick={this.handleBreakSurvey}>Abbrechen</Button>
                            <Fab className={classes.button} variant="extended" color="primary" onClick={this.saveProfileQuestions}>
                                Weiter
                            </Fab>
                        </MuiThemeProvider>
                        {this.state.openValidationDialog ? <ResponsiveTextDialog openDialog="true" closeDialog={this.handleOkClick}
                            title={this.state.dialogTitle} text={this.state.dialogText} /> : null}

                        <Dialog
                            fullScreen={fullScreen}
                            open={this.state.openValidationTextDialog}
                            onClose={this.handleClose}
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">{"Umfrage vorzeitig beenden?"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Willst du diese Bewertung wirklich vorzeitig beenden?
                                    Deine jetzigen Eintragungen gehen dadurch verloren.
                    </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <MuiThemeProvider theme={theme}>
                                    <Button className={classes.buttonBreak} onClick={this.handleCancelSurvey} color="primary">
                                        Bewertung Abbrechen
                            </Button>
                                    <Fab className={classes.button} variant="extended" color="primary" onClick={this.goBackToSurvey}>
                                        Zurück zur Bewertung
                            </Fab>
                                </MuiThemeProvider>
                            </DialogActions>
                        </Dialog>
                    </div>

                </div>
            </div>
        );
    }
}

SurveyAttendee.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        state: state
    };

}

function mapDispatchToProps(dispatch) {
    return {
        setSurveyToSave: (surveyToSave) => dispatch({ type: 'SET_SURVEY_TO_SAVE', surveyToSave }),
    };
}

export default withMobileDialog()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SurveyAttendee)));