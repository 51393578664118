const INITIAL_STATE = {
  accessToken: "",
  formattedDateDailySurveyStatistic: "",
  monthlyStatisticOverview: {},
  user: {
    id: -1
  },
  userProfile: {

  },
  survey: {

  },
  surveyToSave: {

  },
  dailySurvey: {

  },
  editOrAddIdOfSurvey: 0,
  totalScore: 0,
  number: 0,
}

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {

    case 'SET_USER_DATA':
      return {
        ...state, user: action.userData
      }

    case 'SET_ACCESSTOKEN':
      return {
        ...state, accessToken: action.accessToken
      }

    case 'SET_FORMATTED_DATE':
      return {
        ...state, formattedDateDailySurveyStatistic: action.dateStatistic
      }

    case 'SET_MONTHLY_STATS_OVERVIEW':
      return {
        ...state, monthlyStatisticOverview: action.monthlyStats
      }

    case 'SET_SURVEY_TO_SAVE':
      return {
        ...state, surveyToSave: action.surveyToSave
      }

    case 'SET_USER_PROFILE_DATA':
      return {
        ...state, userProfile: action.userProfile
      }

    case 'SET_ID_EDIT_ADD_SURVEY':
      return {
        ...state, editOrAddIdOfSurvey: action.idOfSurvey
      }

    case 'GET_USER_DATA':
      return { ...state, number: state.number - 1 }

    case 'SET_SURVEY':
      return {
        ...state, survey: {
          surveyID: "66666",
          surveyDescription: "Befindlichkeitsumfrage",
          surveyLength: "55151"
        }
      }
 
    case 'CREATE_DAILY_SURVEY':
      return {
        ...state, dailySurvey: {
          surveyDate: action.date,
        }
      }

    case 'SET_DEFAULT_SURVEY_PROFIL':
      return {
        ...state, survey: action.dailySurvey
      }

    case 'SET_TOTAL_SCORE':
      return {
        ...state, totalScore: action.totalScore
      }

    case 'LOGOUT':
      //Set all values to default initialState
      return { ...state, number: state.user.userID }


    default:
      return state;
  }
}

export default reducer;