import React from 'react';
import QuestionItem from "./QuestionItem"
export default function Questionnaire(props) {
    let refID = -1;
    return (
        <div>
            
        {props.questions.map((item) => {
            let refQuestion = null;
            
            if (item.refCondition === "connectedPositive" || item.refCondition === "connectedNegative" || item.refCondition === "connected") {
                //search for connected item right away
                //console.log("item connected", item);
                for (let index in props.questions) {
                    let q = props.questions[index];
                    if (q.id === item.referenceID) {
                        //console.log("found connected item: ", q);
                        refQuestion = q;
                        refID = q.id;
                        break;
                    }

                    //console.log("testing ",q)
                }
            }
            if (refID === item.id) {
                //if it was already used, don't return it again
                refID = -1;
                return null;
            }
            return (
                
                <div key={item.id}>
            
                { refQuestion != null? 
                    <div>
                        <div className={"connectedQuestions " + (item.refCondition === "connectedPositive"?"connectedQuestionsPositive": item.refCondition === "connectedNegative" ? "connectedQuestionsNegative":"")}>
                            <QuestionItem key={item.id} item={item} onChange={(item, newValue) => props.onChange(item, newValue)} useSmileys={props.useSmileys}/>
                            <QuestionItem key={refQuestion.id} item={refQuestion} onChange={(refQuestion, newValue) => props.onChange(refQuestion, newValue)} useSmileys={props.useSmileys} isReferenceItem={true}/>
                        </div>
                        <div><br/></div>
                    </div>
                    :
                    <QuestionItem key={item.id} item={item} onChange={(item, newValue) => props.onChange(item, newValue)} useSmileys={props.useSmileys}/>
            }  
            </div>)
        } )}
        </div>
    );
}