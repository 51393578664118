import React, { Component } from 'react';
import '../../styles/App.css';
import HeaderApp from '../header_footer/index_headerApp';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import axios from "axios";
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import {apiURL} from '../backend/constants.js';

import happy_active from '../../icons/happy(1)_active.png';
import happy_inactive from '../../icons/happy(1)_inactive.png';
import friendly_active from '../../icons/friendly(2)_active.png';
import friendly_inactive from '../../icons/friendly(2)_inactive.png';
import neutral_active from '../../icons/neutral(3)_active.png';
import neutral_inactive from '../../icons/neutral(3)_inactive.png';
import bad_active from '../../icons/bad(4)_active.png';
import bad_inactive from '../../icons/bad(4)_inactive.png';
import sad_active from '../../icons/sad(5)_active.png';
import sad_inactive from '../../icons/sad(5)_inactive.png';


const styles = theme => ({
    button: {
        marginRight: 1,
        "&:hover": {
            backgroundColor: "#90B134"
        }
    }
});

function nextDayDate(dateString) {
    var dNext = new Date(dateString);
    dNext.setDate(dNext.getDate()+1)
    let mprefix = (dNext.getMonth() < 9? "0" :"")
    let dprefix = (dNext.getDate() < 10? "0":"")
    return dNext.getFullYear() + "-" + mprefix+(dNext.getMonth()+1) + "-" + dprefix + dNext.getDate()

}

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#90B134',
            contrastText: "white" //button text white instead of black 
        }
    },
    typography: {
        button: {
            textTransform: "none",
            fontSize: 16,
        }
    }
});


class DailySurveyStats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statistic: {},
            screenName: "Tageswertung vom",
            statisticLoaded: false,
            statsReloaded: false,

            textPositiveDropdown: "",
            textNegativeDropdown: "",

            dropdownItemAnswerPositive: "",
            textAnswerPositive: "",

            dropdownItemAnswerNegative: "",
            textAnswerNegative: ""
        };
        this.handleBackClick = this.handleBackClick.bind(this);
    }

    componentDidMount() {
        this.getHeaderTitle();
        var that = this;

        if (this.props.state.user.id === -1) {
            var x = document.cookie;
            if (x === "") {
                alert("Ihre Session ist leider nicht mehr aktiv - bitte loggen Sie sich erneut ein.")
                this.props.history.push('/');
            } else {
                var accessToken = null;
                var standardSurveyId = null;
                var userId = null;
                new Promise(function (resolve, reject) {

                    setTimeout(() => resolve(1), 100);

                }).then(function (result) {

                    //Splitting the cookie to get the values
                    var values = x.split("{");
                    values = values[1].split(",");
                    accessToken = values[0].split(":")[1].replace(/"/g, "") ;
                    standardSurveyId = values[1].split(":")[1].replace(/"/g, "") ;
                    userId = values[3].split(":")[1].replace(/"/g, "") ;

                    //store the accesToken and userId in the state 
                    //that.setState({ accessTokenReload: accessToken[1].replace(/"/g, "") });
                    //that.setState({ standardSurveyIdReload: standardSurveyId[1].replace(/"/g, "") });
                    //that.setState({ userIdReload: userId[1].replace(/}/g, "") });
                }).then(function (result) {

                    var restCallURL = apiURL + "Surveys/userAlive";
                    axios.get(restCallURL, { headers: { Authorization: accessToken } }).then((res) => {
                    }).catch((error) => {
                        //Show Error Dialog 
                        alert("Ihre Session ist leider nicht mehr aktiv - bitte loggen Sie sich erneut ein.");
                        this.props.history.push('/');
                    }).then(() => {
                        that.props.setAccessToken(accessToken);
                    });
                }).then(function (result) {
                    if (that.props.state.formattedDateDailySurveyStatistic !== "") {
                        var dayForStats = that.props.state.formattedDateDailySurveyStatistic;
                        var restCallURL = apiURL + "Surveys/stats?from=" + dayForStats + "&to=" + nextDayDate(dayForStats)
                        //console.log(restCallURL)
                        axios.get(restCallURL, { headers: { Authorization: accessToken } }).then((res) => {
                            that.setState({ statistic: res.data.stats.dailyStats[dayForStats.toString()],statisticLoaded:true,accessTokenReload:accessToken,standardSurveyIdReload: standardSurveyId,userIdReload: userId});
                        }).catch((error) => {
                            //Show Error Dialog
                            //alert(error.response.statusText);
                            that.setState({ statisticLoaded: false });
                        })//.then(function (result) {
                        //    that.setState({ statisticLoaded: true });
                        //});
                    } else {
                        that.setState({ statsReloaded: true,accessTokenReload:accessToken,standardSurveyIdReload: standardSurveyId,userIdReload: userId });
                    }
                });
            }
        } else {
            //this.setState({ accessTokenReload: this.props.state.user.id });

            new Promise(function (resolve, reject) {

                setTimeout(() => resolve(1), 100);

            }).then(function (result) {

                var dayForStats = that.props.state.formattedDateDailySurveyStatistic;
                var restCallURL = apiURL + "Surveys/stats?from=" + dayForStats + "&to=" + nextDayDate(dayForStats);
                axios.get(restCallURL, { headers: { Authorization: that.props.state.user.id } }).then((res) => {
                    that.setState({ statistic: res.data.stats.dailyStats[dayForStats.toString()],statisticLoaded: true,accessTokenReload: that.props.state.user.id });
                }).catch((error) => {
                    //Show Error Dialog
                    that.setState({ statisticLoaded: false,accessTokenReload: that.props.state.user.id });
                    //alert(error.response.statusText);
                })//.then(function (result) {
                //    that.setState({ statisticLoaded: true });
                //});
            });
        }
    }

    SurveyStats(dailyStats) {
        var dailyQuestionStats = [];
        Object.entries(dailyStats.questions).forEach(([key, value]) => {
            var happyActive = false;
            var friendlyActive = false;
            var neutralActive = false;
            var badActive = false;
            var sadActive = false;

            if (value.avg < 1.5) {
                happyActive = true;
            } else if (value.avg >= 1.5 && value.avg < 2.5) {
                friendlyActive = true;
            } else if (value.avg >= 2.5 && value.avg < 3.5) {
                neutralActive = true;
            } else if (value.avg >= 3.5 && value.avg < 4.5) {
                badActive = true;
            } else if (value.avg >= 4.5) {
                sadActive = true;
            }
            //console.log(value);
            dailyQuestionStats.push(<li key={value.qid}>
                <div>
                    <Typography variant="h6" color="textPrimary">
                        {value.text}
                    </Typography>
                    <div className="surveyHelpSmileyDiagram">
                        <img src={happyActive ? happy_active : happy_inactive} className="profile-img" alt="happy face" width="40px" height="40px" style={{ marginRight: "5px" }} />
                        <img src={friendlyActive ? friendly_active : friendly_inactive} className="profile-img" alt="friendly face" width="40px" height="40px" style={{ marginRight: "5px" }} />
                        <img src={neutralActive ? neutral_active : neutral_inactive} className="profile-img" alt="neutral face" width="40px" height="40px" style={{ marginRight: "5px" }} />
                        <img src={badActive ? bad_active : bad_inactive} className="profile-img" alt="unfriendly face" width="40px" height="40px" style={{ marginRight: "5px" }} />
                        <img src={sadActive ? sad_active : sad_inactive} className="profile-img" alt="sad face" width="40px" height="40px" style={{ marginRight: "5px" }} />
                    </div>
                    <br />
                </div>
            </li>);
        });
        return dailyQuestionStats;
    }

    DropdownStats(dailyStats) {
        var dailyDropdownStats = [];

        var textPositiveDrop = "";
        var dropItemAnswerPos = "";
        var textAnswerPos = "";
        var textNegativeDrop = "";
        var dropItemAnswerNeg = "";
        var textAnswerNeg = "";

        //console.log(dailyStats)

        for (var i = 0; i < dailyStats.summaries.length; i++) {
            if (dailyStats.summaries[i].qid === 15) {
                textNegativeDrop = dailyStats.summaries[i].text;
                dropItemAnswerNeg = dailyStats.summaries[i].answers;
            } else if (dailyStats.summaries[i].qid === 16) {
                textAnswerNeg = dailyStats.summaries[i].answers;
            } else if (dailyStats.summaries[i].qid === 17) {
                textPositiveDrop = dailyStats.summaries[i].text;
                dropItemAnswerPos = dailyStats.summaries[i].answers;
            } else if (dailyStats.summaries[i].qid === 18) {
                textAnswerPos = dailyStats.summaries[i].answers;
            }
        }

        if (dropItemAnswerPos === "") {
            dropItemAnswerPos = "keine Nennung"
        }
        if (dropItemAnswerNeg === "") {
            dropItemAnswerNeg = "keine Nennung"
        }
        let dropItemNegParts = dropItemAnswerNeg.split(" und ");
        let dropItemPosParts = dropItemAnswerPos.split(" und ");
        let textAnswerNegParts = textAnswerNeg.split(" und ");
        let textAnswerPosParts = textAnswerPos.split(" und ");


        dailyDropdownStats.push(
            <div key={dailyDropdownStats.length}>
                <div className="inlineDropdownStats">
                    <Typography variant="h6" color="textPrimary">
    {textNegativeDrop} { dropItemNegParts.map((value, index) => {
                                    return index > 0 ? 
                                    <span key={index}><span className="answersDropdownAnd"> / </span><span className="answersDropdown"><b>{value}</b></span></span> : 
                                    <span className="answersDropdown" key={index}><b>{value}</b></span>
                                  })} 
                       
                    </Typography>
                </div>
                <div>
                    <p className="textAnswers"><i>{ textAnswerNegParts.map((value, index) => {
                                    return index > 0 ? " / " + value : value
                                  })} </i></p>
                </div>
                <br />
            </div>
        );

        dailyDropdownStats.push(
            <div key={dailyDropdownStats.length}>
                <div className="inlineDropdownStats">
                    <Typography variant="h6" color="textPrimary">
                        {textPositiveDrop} { dropItemPosParts.map((value, index) => {
                                    return index > 0 ? 
                                    <span key={index}><span className="answersDropdownAnd"> / </span><span className="answersDropdown"><b>{value}</b></span></span> : 
                                    <span className="answersDropdown" key={index}><b>{value}</b></span>
                                  })} 
                    </Typography>
                </div>
                <div>
                    <p className="textAnswers"><i> {textAnswerPosParts.map((value, index) => {
                                    return index > 0 ? " / " + value : value
                                  })} </i></p>
                </div>
                <br />
            </div>
        );

        return dailyDropdownStats;
    }


    handleBackClick() {
        this.props.history.push("/dashboard");
    }

    getHeaderTitle = () => {
        var titleHeader = "Tagesbewertung";
        if (this.props.state.formattedDateDailySurveyStatistic !== "") {
            var splittedDate = this.props.state.formattedDateDailySurveyStatistic.split("-");

            var newDate = parseInt(splittedDate[2]) + "." + parseInt(splittedDate[1]) + "." + splittedDate[0];

            titleHeader = this.state.screenName + "  " + newDate;
        }
        this.setState({ headerTitle: titleHeader });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="App">
                <div className="header">
                    <HeaderApp screenName={this.state.headerTitle} thus={this} />
                </div>
                {this.state.statsReloaded ? <div><div className="statsReloadedGoBack">Rufe das Tagebuch auf und wähle erneut einen Tag aus, auf den du zurückblicken möchtest</div>
                    <div>
                        <MuiThemeProvider theme={theme}>
                            <Fab className={classes.button} variant="extended" color="primary" onClick={this.handleBackClick}>
                                Zurück
                            </Fab>
                        </MuiThemeProvider>
                    </div>
                </div>
                    :
                    <div className="dailyStats">
                        {this.state.statisticLoaded ?
                            <div className="contentSurveyDescription">
                                 <div className="surveyInformationCount">Anzahl Wertungen: {this.state.statistic.questions[0].cnt}</div> 

                                <div className="surveyInformation">

                                    <ul className="listDailyStats">
                                        {this.SurveyStats(this.state.statistic)}
                                    </ul>
                                    <div>
                                        {this.DropdownStats(this.state.statistic)}
                                    </div>
                                    <MuiThemeProvider theme={theme}>
                                        <Fab className={classes.button} variant="extended" color="primary" onClick={this.handleBackClick}>
                                            Zurück
                                        </Fab>
                                    </MuiThemeProvider>
                                </div>
                            </div>
                            :
                            <div></div>}
                    </div>}

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        state: state
    };

}

function mapDispatchToProps(dispatch) {
    return {
        setAccessToken: (accessToken) => dispatch({ type: 'SET_ACCESSTOKEN', accessToken })
    };

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DailySurveyStats));