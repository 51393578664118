import React, { Component } from 'react';
import '../../styles/App.css';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Redirect } from 'react-router';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import CalendarActiveIcon from '../../icons/calendar_active.png';
import CalendarInactiveIcon from '../../icons/calendar_inactive.png';
import StatisticOverviewKidsActiveIcon from '../../icons/statisticOverviewKids_active.png';
import StatisticOverviewKidsInactiveIcon from '../../icons/statisticOverviewKids_inactive.png';
import StatisticKidsActiveIcon from '../../icons/statisticKids_active.png';
import StatisticKidsInactiveIcon from '../../icons/statisticKids_inactive.png';



const styles = theme => ({
    root: {
        width: "100%",
        backgroundColor: 'transparent',
    }
});

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#fff',
        },
        secondary: {
            main: "#4D4D4D"
        }
    }
});

class FooterApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            goToCalendar: false,
            goToStatistic: false,
            goToOverviewStatistic: false,

            calendar: false,
            statistic: false,
            overviewStatistic: false,
        };
    }

    componentDidMount() {
        if (this.props.currentScreen === "dashboard") {
            this.setState({ calendar: true });
        } else if (this.props.currentScreen === "statisticKids") {
            this.setState({ statistic: true });
        } else if (this.props.currentScreen === "statisticKidsOverview") {
            this.setState({ overviewStatistic: true });
        }
    }

    render() {
        const { classes } = this.props;
        if (this.state.goToCalendar) {
            return <Redirect push to="/dashboard" />;
        } if (this.state.goToStatistic) {
            return <Redirect push to="/statistic_kids" />;
        } if (this.state.goToOverviewStatistic) {
            return <Redirect push to="/statistic_kids_overview" />;
        }
        return (
            <div>
                <div className="App">
                    <div className={classes.root}>
                        <AppBar position="static" style={{ backgroundColor: '#E1F99D' }}>
                            <Toolbar>
                                <MuiThemeProvider theme={theme}>

                                    <BottomNavigation
                                        showLabels
                                        className={classes.root}
                                        onChange={(event, newValue) => {
                                            if (newValue === 0 && this.props.currentScreen !== "dashboard") {
                                                this.setState({ goToCalendar: true });
                                            } else if (newValue === 1 && this.props.currentScreen !== "statisticKids") {
                                                this.setState({ goToStatistic: true });
                                            } else if (newValue === 2 && this.props.currentScreen !== "statisticKidsOverview") {
                                                this.setState({ goToOverviewStatistic: true });
                                            }
                                        }}
                                    >
                                        {this.state.calendar ?
                                            <BottomNavigationAction icon={<img src={CalendarInactiveIcon} alt="calendar icon" height="45" width="45"/>} /> :
                                            <BottomNavigationAction icon={<img src={CalendarActiveIcon}  alt="calendar icon" height="45" width="45"/>} />}
                                        {this.state.statistic ?
                                            <BottomNavigationAction icon={<img src={StatisticKidsInactiveIcon} alt="statistics icon" height="45" width="45"/>} /> :
                                            <BottomNavigationAction icon={<img src={StatisticKidsActiveIcon}  alt="statistics icon" height="45" width="45"/>} />}
                                        {this.state.overviewStatistic ?
                                            <BottomNavigationAction icon={<img src={StatisticOverviewKidsInactiveIcon} alt="overview icon" height="45" width="45"/>} /> :
                                            <BottomNavigationAction icon={<img src={StatisticOverviewKidsActiveIcon} alt="overview icon" height="45" width="45"/>} />}
                                    </BottomNavigation>
                                </MuiThemeProvider>

                            </Toolbar>
                        </AppBar>
                    </div>
                </div>
            </div >
        );
    }
}


export default withStyles(styles)(FooterApp);
